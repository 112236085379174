import { LogoIcon } from '@assets/icons'
import { AppBar, IconButton, Toolbar } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

const Navigation = () => {
  const navigate = useNavigate()
  return (
    <AppBar color="default" position="relative" sx={{ padding: 0, zIndex: 2 }}>
      <Toolbar>
        <IconButton onClick={() => navigate('/')} disableRipple sx={{ mr: 2 }}>
          <LogoIcon data-testid="logo-icon" sx={{ width: '96px', height: '50px' }} />
        </IconButton>
        <DesktopMenu />
        <MobileMenu />
      </Toolbar>
    </AppBar>
  )
}

export default Navigation
