import notFoundAnimation from '@assets/animations/notFoundAnimation.json'
import offline from '@assets/images/offline.png'
import { Stack, Box, Container, Typography, SxProps } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useLottie } from 'lottie-react'
import React from 'react'

interface IDtpErrorProps {
  suptitle?: string
  title: string
  subtitle: string
  slot: React.ReactNode
  sx?: SxProps
  image?: boolean
}

const DtpError = ({ suptitle, title, subtitle, slot, image = false, sx }: IDtpErrorProps) => {
  const options = {
    animationData: notFoundAnimation,
    loop: true,
  }
  const { View } = useLottie(options)

  return (
    <Container>
      <Stack
        sx={{
          flexDirection: { xs: 'column-reverse', md: 'row' },
          marginTop: { xs: 6, sm: 11 },
          marginBottom: 3,
          ...sx,
        }}
      >
        <Box>
          <Show when={!!suptitle}>
            <Typography variant="heading4">{suptitle}</Typography>
          </Show>
          <Typography variant="titleSmall" sx={{ margin: '0 0 16px 0' }}>
            {title}
          </Typography>
          <Typography variant="lead" sx={{ margin: '0 0 32px 0' }}>
            {subtitle}
          </Typography>
          <Box>{slot}</Box>
        </Box>
        <Show when={!image}>
          <Box>{View}</Box>
        </Show>
        <Show when={image}>
          <Box>
            <img src={offline} width="100%" alt="offline-icon" />
          </Box>
        </Show>
      </Stack>
    </Container>
  )
}

export default DtpError
