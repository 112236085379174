import i18next from 'i18next'
import * as yup from 'yup'

const { t } = i18next

const lettersRegex = /^[a-zA-ZäöüÄÖÜ' -]+$/

const validateSwissPhone = (fieldType: 'mobile' | 'landline') => {
  return yup
    .string()
    .max(20, t('personal_data.validation_error_phone_number_length'))
    .nullable()
    .test(fieldType === 'mobile' ? 'is-swiss-mobile' : 'is-swiss-landline', function (value) {
      if (!value) return true

      const formattedValue = value.replace(/\s+/g, '')
      const [countryCode, phonePrefix] = [formattedValue.substring(0, 3), formattedValue.substring(3, 5)]

      const isMobile = countryCode === '+41' && Number(phonePrefix) > 74 && Number(phonePrefix) < 80
      const isLandline = countryCode === '+41' && (Number(phonePrefix) < 75 || Number(phonePrefix) > 79)

      if ((fieldType === 'mobile' && isMobile) || (fieldType === 'landline' && isLandline)) return true

      return this.createError({
        path: fieldType,
        message: t(
          fieldType === 'mobile'
            ? 'personal_data.validation_error_not_a_mobile_number'
            : 'personal_data.validation_error_not_a_landline_number'
        ),
      })
    })
}

const baseSchema = {
  mobile: validateSwissPhone('mobile'),
  landline: validateSwissPhone('landline'),
  email: yup
    .string()
    .email(t('personal_data.validation_error_email_format'))
    .max(80, t('personal_data.validation_error_email_length'))
    .required(t('personal_data.validation_error_email_required')),
}

const personSpecificSchema = {
  lastName1: yup
    .string()
    .matches(lettersRegex, t('personal_data.validation_error_input_name'))
    .max(30, t('personal_data.validation_error_lastname_max_length'))
    .required(t('personal_data.validation_error_lastname_required')),
  firstName2: yup
    .string()
    .matches(lettersRegex, t('personal_data.validation_error_input_name'))
    .max(30, t('personal_data.validation_error_firstname_max_length'))
    .nullable(),
  lastName2: yup
    .string()
    .matches(lettersRegex, t('personal_data.validation_error_input_name'))
    .max(30, t('personal_data.validation_error_lastname_max_length'))
    .nullable(),
}

const personSchema = yup
  .object({
    ...baseSchema,
    ...personSpecificSchema,
  })
  .test('firstName2-lastName2-validation', 'Both fields must be filled or both should be empty', function (values) {
    const { firstName2, lastName2 } = values

    if ((!firstName2 && !lastName2) || (firstName2 && lastName2)) {
      return true
    }

    return this.createError({
      path: firstName2 ? 'lastName2' : 'firstName2',
      message: t('personal_data.validation_error_two_fields_dependency'),
    })
  })

const organisationSchema = yup.object(baseSchema)

export const accountDetailsSchema = {
  Person: personSchema,
  Organisation: organisationSchema,
}
