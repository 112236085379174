import DtpInfoTooltip from '@components/DtpInfoTooltip/DtpInfoTooltip'
import { Typography, useTheme } from '@mui/material'

interface ITooltipLabel {
  label: string
  tooltipLabel: string
}

const TooltipLabel = ({ label, tooltipLabel }: ITooltipLabel) => {
  const theme = useTheme()
  return (
    <>
      {label}
      <DtpInfoTooltip
        id={'123'}
        content={
          <Typography sx={{ my: 0 }} color={theme.palette.text.primary} fontFamily="GothamBook" variant="bodySmall">
            {tooltipLabel}
          </Typography>
        }
        tooltipWidth={352}
        iconSize={18}
        placement="top"
        iconButtonSx={{ color: theme.palette.primary.main }}
      />
    </>
  )
}

export default TooltipLabel
