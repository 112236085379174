export const gray = {
  50: '#FAFAFA',
  75: '#F4F5F4',
  100: '#EFF0EF',
  200: '#D5D8D5',
  300: '#BFC4BF',
  400: '#8D968D',
  500: '#6A716A',
  600: '#4F544F',
  700: '#3C403C',
  800: '#232623',
  900: '#090A09',
}

export const green = {
  50: '#F1F9E8',
  75: '#E4F3D3',
  100: '#C8E6A4',
  200: '#A4D270',
  300: '#86BC46',
  400: '#6C9C30',
  500: '#53821F',
  600: '#365F10',
  700: '#21420B',
  800: '#1A330B',
  900: '#060E03',
}

export const khaki = {
  50: '#FBFCE2',
  75: '#F7FBCB',
  100: '#F3FBAE',
  200: '#DBE873',
  300: '#C8DD2D',
  400: '#A7B805',
  500: '#8A9C0B',
  600: '#6D7E21',
  700: '#435505',
  800: '#2F3905',
  900: '#101000',
} as const

export const blue = {
  50: '#F0F9FC',
  75: '#D7EEF7',
  100: '#BFE3F4',
  200: '#83D0F5',
  300: '#44BFF5',
  400: '#00ACF5',
  500: '#01A3E8',
  600: '#0099DB',
  700: '#007DB2',
  800: '#014F70',
  900: '#011F2C',
} as const

export const yellow = {
  50: '#FFFBE6',
  75: '#FFF7D7',
  100: '#FDF2A8',
  200: '#FEEF88',
  300: '#FFEC6E',
  400: '#FFE500',
  500: '#FFDE01',
  600: '#FDC300',
  700: '#B98F00',
  800: '#785C01',
  900: '#362A01',
} as const

export const orange = {
  50: '#FFF5F0',
  75: '#FCE2D5',
  100: '#FCE2D5',
  200: '#F5A376',
  300: '#F0843B',
  400: '#EC6A08',
  500: '#E55312',
  600: '#D73D19',
  700: '#A52F14',
  800: '#681E0D',
  900: '#2D0D06',
} as const

export const violet = {
  25: '#F194FF',
  50: '#F7EDF7',
  75: '#E8D8E7',
  100: '#D9B6D2',
  200: '#C692BC',
  300: '#B161A2',
  400: '#9F358B',
  500: '#972784',
  600: '#901A7D',
  700: '#69135B',
  800: '#440C3B',
  900: '#260721',
} as const
