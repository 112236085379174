export const isNil = (value: unknown): value is null | undefined => {
  // eslint-disable-next-line eqeqeq
  return value == null
}

export const isEmpty = (val: any): boolean => val == null || !(Object.keys(val) || val).length // eslint-disable-line

export const replaceEmptyStringsWithNull = <T extends object>(
  obj: T
): { [K in keyof T]: T[K] extends '' ? null : T[K] } => {
  const updatedObj = { ...obj } as { [K in keyof T]: T[K] extends '' ? null : T[K] }

  Object.keys(updatedObj).forEach((key) => {
    const typedKey = key as keyof T
    if (updatedObj[typedKey] === '') {
      updatedObj[typedKey] = null as (typeof updatedObj)[typeof typedKey]
    }
  })

  return updatedObj
}
