import { Box, CircularProgress, SxProps, styled } from '@mui/material'

interface IChartLoaderProps {
  sx?: SxProps
}

const ChartLoader = ({ sx }: IChartLoaderProps) => {
  const ProgressBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 296,
    width: '100%',
  })
  return (
    <ProgressBox sx={sx}>
      <CircularProgress data-testid="chartLoader" />
    </ProgressBox>
  )
}

export default ChartLoader
