import { chartAxisEnum } from '@enums/chartAxisFormats'
import dayjs from 'dayjs'
import React from 'react'

export interface IHandleTooltipPositionAndOpenProps {
  e: {
    x: number
    y: number
    width: number
  }
  isMobile: boolean
  isTablet: boolean
  isSmallMobile: boolean
  viewportWidth: number
  TOOLTIP_INITIAL_X: number
  TOOLTIP_INITIAL_Y: number
  setTooltipPosition?: CallableFunction
  setTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>
  tooltipOpen: boolean
}
export interface IHandleCompareTooltipPositionAndOpenProps {
  e: {
    tooltipPosition: object
    forecast: string
    timestampStartOfPeriod: string
    x: number
    y: number
    width: number
    height: number
  }
  tooltipOpen: boolean
  setTooltipOpen: CallableFunction
  setTooltipPosition: CallableFunction
  isSmallMobile: boolean
  setWrapperStylePositionTooltip: CallableFunction
  TOOLTIP_INITIAL_X: number
  TOOLTIP_INITIAL_Y: number
  viewportWidth: number
}

export const handleTooltipPositionAndOpen = ({
  e,
  isMobile,
  isTablet,
  isSmallMobile,
  viewportWidth,
  setTooltipPosition,
  TOOLTIP_INITIAL_X,
  TOOLTIP_INITIAL_Y,
  setTooltipOpen,
  tooltipOpen,
}: IHandleTooltipPositionAndOpenProps) => {
  const offset = isMobile || isTablet ? 320 : 500
  const mobileTooltipPosition = e.x + e.width / 2 - 277
  const mobileTooltipPositionCalc = mobileTooltipPosition > 0 ? mobileTooltipPosition : mobileTooltipPosition + 143
  const xTooltipPositionCalc = e.x + offset < viewportWidth ? e.x - 0 : mobileTooltipPositionCalc
  if (isSmallMobile) {
    setTooltipPosition?.({ x: TOOLTIP_INITIAL_X, y: TOOLTIP_INITIAL_Y })
  } else {
    setTooltipPosition?.({
      x: xTooltipPositionCalc,
      y: TOOLTIP_INITIAL_Y,
    })
  }
  !tooltipOpen && setTooltipOpen(true)
}

export const handleFormatXAxisHelper = (value: string, index: number, xAxisDate: chartAxisEnum, isMobile: boolean) => {
  const dateFormat = isMobile ? 'DD MMM' : 'DD'
  if (xAxisDate === chartAxisEnum.MONTH) {
    return dayjs(value).format('MMM')
  }

  if (xAxisDate === chartAxisEnum.YEAR) {
    return dayjs(value).format('YYYY')
  }

  if (isMobile) {
    const visibleIndices = [2, 9, 16, 23]

    return visibleIndices.includes(index) ? dayjs(value).format(dateFormat) : ''
  }
  return dayjs(value).format(dateFormat)
}

export const intervalValueHelper = (xAxisDate: chartAxisEnum, isWeb: boolean, isTablet: boolean) => {
  let interval = undefined

  if (xAxisDate === chartAxisEnum.MONTH) {
    interval = 0
  } else if (isWeb || isTablet) {
    interval = isTablet ? 1 : 0
  }

  return interval
}

export const handleTooltipOpenAndPositionCompare = (props: IHandleCompareTooltipPositionAndOpenProps) => {
  const {
    isSmallMobile,
    e,
    setTooltipOpen,
    setTooltipPosition,
    tooltipOpen,
    viewportWidth,
    setWrapperStylePositionTooltip,
    TOOLTIP_INITIAL_X,
    TOOLTIP_INITIAL_Y,
  } = props
  if (isSmallMobile) {
    setTooltipPosition?.({ x: TOOLTIP_INITIAL_X, y: TOOLTIP_INITIAL_Y })
    const isClickedRight = e.x > Math.floor(viewportWidth / 2)
    const offsetLeft = isClickedRight ? viewportWidth / 4 - e.width * 1.5 : 0
    setWrapperStylePositionTooltip({ left: offsetLeft, top: -150 })
  } else {
    e.timestampStartOfPeriod &&
      setTooltipPosition?.({
        x: e.x + e.width / 2 - 143,
        y: e.forecast ? TOOLTIP_INITIAL_Y : -140,
      })
  }
  !tooltipOpen && setTooltipOpen(true)
}

export const getFakeDataValue = (maxValue: number) => {
  const randomNumb = Math.round(Math.random() * (75 - 60) + 60)
  return (maxValue * randomNumb) / 100
}
