import { HomeIcon, PowerConsumptionIcon, BillsMenuItemIcon } from '@assets/icons'
import Contracts from '@pages/Contracts/Contracts'
import CriticalError from '@pages/CriticalError/CriticalError'
import DirectDebit from '@pages/DirectDebit/DirectDebit'
import EBill from '@pages/EBill/EBill'
import Help from '@pages/Help/Help'
import Home from '@pages/Home/Home'
import InvoiceDetails from '@pages/InvoiceDetails/InvoiceDetails'
import InvoiceSettings from '@pages/InvoiceSettings/InvoiceSettings'
import Invoices from '@pages/Invoices/Invoices'
import MaintenancePage from '@pages/Maintenance/MaintenancePage'
import ManagedAccounts from '@pages/ManagedAccounts/ManagedAccounts'
import ManualPayment from '@pages/ManualPayment/ManualPayment'
import NotFound from '@pages/NotFound/NotFound'
import PersonalData from '@pages/PersonalData/PersonalData'
import PowerConsumption from '@pages/PowerConsumption/PowerConsumption'
import i18n from 'i18next'

import PrivateRoute from './PrivateRoute'

const { t } = i18n

export const navRoutes = [
  {
    path: '/',
    element: <Home />,
    icon: <HomeIcon />,
    name: 'navigation.navi_home',
  },
  {
    path: t('navigation_url.energy_consumption'),
    element: <PrivateRoute />,
    name: 'navigation.navi_energy_consumption',
    icon: <PowerConsumptionIcon />,
    children: [{ element: <PowerConsumption />, path: t('navigation_url.energy_consumption') }],
  },
  {
    path: t('navigation_url.invoices'),
    element: <PrivateRoute />,
    name: 'navigation.navi_invoices',
    icon: <BillsMenuItemIcon />,
    children: [
      { index: true, element: <Invoices />, path: t('navigation_url.invoices') },
      {
        path: ':id',
        element: <InvoiceDetails />,
      },
    ],
  },
]

export const profileRoutes = [
  {
    path: t('navigation_url.personal_data'),
    element: <PrivateRoute />,
    name: 'navigation.navi_personal_data',
    children: [
      {
        path: t('navigation_url.personal_data'),
        element: <PersonalData />,
      },
    ],
  },
  {
    path: t('navigation_url.contracts'),
    element: <PrivateRoute />,
    name: 'navigation.navi_contracts',
    children: [
      {
        path: t('navigation_url.contracts'),
        element: <Contracts />,
      },
    ],
  },
  {
    path: t('navigation_url.manage_accounts'),
    element: <PrivateRoute />,
    name: 'navigation.navi_managed_accounts',
    children: [
      {
        path: t('navigation_url.manage_accounts'),
        element: <ManagedAccounts />,
      },
    ],
  },
]

export const settingsRoutes = [
  // TODO: uncomment when the page is ready
  // {
  //   path: '/login-security',
  //   element: <div>Login & Security</div>,
  //   name: 'Login and Security',
  // },
  {
    path: t('navigation_url.invoices_settings'),
    name: 'navigation.navi_invoices_settings',
    element: <PrivateRoute />,
    children: [
      {
        path: t('e_bill.e_bill'),
        element: <EBill />,
        name: t('e_bill.e_bill'),
      },
      {
        path: t('invoices_settings.navigation_url'),
        element: <DirectDebit />,
        name: t('invoices_settings.direct_debit'),
      },
      {
        path: t('manual_payment.navigation_url'),
        element: <ManualPayment />,
        name: t('manual_payment.manual_payment'),
      },
      {
        path: t('navigation_url.invoices_settings'),
        element: <InvoiceSettings />,
      },
    ],
  },
]

export const routes = [
  ...navRoutes,
  ...profileRoutes,
  ...settingsRoutes,
  {
    path: '/help',
    element: <Help />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]

export const publicRoutes = [
  {
    path: '/',
    element: <CriticalError />,
  },
  {
    path: '*',
    element: <CriticalError />,
  },
]

export const maintenanceRoutes = [
  {
    path: '*',
    element: <MaintenancePage />,
  },
]
