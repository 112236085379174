import { ListSubheader, FormControlLabel, Stack, Typography, Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ICheckboxControlProps {
  checked?: boolean
  onChange?: () => void
}

const TerminatedContractsCheckbox = ({ checked, onChange }: ICheckboxControlProps) => {
  const { t } = useTranslation()
  return (
    <ListSubheader key="expiredContracts" sx={{ p: 0, py: 1, borderTop: '1px solid #D5D8D5' }}>
      <Stack direction="row" spacing={1} alignItems="center" gap={2}>
        <Typography noWrap id="expiredContractsItem">
          <FormControlLabel
            control={<Checkbox sx={{ ml: 2 }} />}
            checked={checked}
            onChange={onChange}
            label={t('terminated_contracts')}
          />
        </Typography>
      </Stack>
    </ListSubheader>
  )
}

export default TerminatedContractsCheckbox
