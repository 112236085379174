import { cardPalette } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import { TableRow, TableCell, Button, styled } from '@mui/material'

export const CustomTableRow = styled(TableRow)({
  '&:hover': {
    backgroundColor: cardPalette.stackBgColor,
    boxShadow: `0 0 0 10px ${cardPalette.stackBgColor}`,
    borderRadius: theme.borderRadius.small,
    cursor: 'pointer',
  },
})

export const CustomTableCell = styled(TableCell)({
  padding: 0,
  border: 'none',
  textAlign: 'left',
  verticalAlign: 'center',
})

export const CustomDownloadButton = styled(Button)(({ theme }) => ({
  p: 0,
  minWidth: 'max-content',
  '.MuiButton-startIcon': {
    transition: 'color .15s ease-out, background-color .15s ease-out',
    borderRadius: '50%',
    padding: '4px',
    '& > :nth-of-type(1)': {
      width: '18px',
      height: '18px',
    },
  },
  '&:hover .MuiButton-startIcon': {
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
    transition: 'color .15s ease-out, background-color .15s ease-out',
  },
}))
