import { FormControl, FormHelperText, InputLabel, Typography } from '@mui/material'

import { ErrorExclamationIcon } from '../../../assets/icons'

export interface FormProps {
  label?: string | React.ReactNode
  error?: boolean
  required?: boolean
  helperText?: string
  fullWidth?: boolean
}

export interface FormWrapperProps extends FormProps {
  children: React.ReactNode
}
const LabelText = ({ label, required }: FormProps) => {
  if (!label) return <></>
  return (
    <span>
      {label}
      {required && (
        <Typography component="span" color={'error'} ml={1}>
          *
        </Typography>
      )}
    </span>
  )
}

const HelperText = ({ error = false, helperText }: FormProps) => (
  <>
    {error && helperText && (
      <FormHelperText error>
        <ErrorExclamationIcon />
        {helperText}
      </FormHelperText>
    )}
  </>
)

const DtpFormWrapper = ({ fullWidth = false, children, ...rest }: FormWrapperProps) => {
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel disableAnimation shrink sx={{ transform: 'unset' }}>
        <LabelText {...rest} />
        {children}
        <HelperText {...rest} />
      </InputLabel>
    </FormControl>
  )
}

export default DtpFormWrapper
