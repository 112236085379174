import { DtpInvoiceCardWrapper } from '@components/DtpInvoiceCard/DtpInvoiceCard'
import { Box, Stack, Skeleton } from '@mui/material'

const DtpInvoiceCardSkeleton = () => {
  return (
    <DtpInvoiceCardWrapper>
      <Box mb={1}>
        <Skeleton width="60%" />
      </Box>
      <Stack direction="row" gap={4} justifyContent="space-between">
        <Skeleton width="40%" />
        <Skeleton width="30%" />
      </Stack>
      <Stack direction="row" gap={7} justifyContent="space-between">
        <Skeleton width="50%" />
        <Skeleton width="30%" />
      </Stack>
      <Stack direction="row" gap={10} justifyContent="space-between">
        <Skeleton width="100%" />
        <Skeleton width="100%" />
      </Stack>
    </DtpInvoiceCardWrapper>
  )
}

export default DtpInvoiceCardSkeleton
