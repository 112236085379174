import { Stack, SxProps, Typography } from '@mui/material'

const ContentItem = ({
  label,
  value,
  labelSx,
  valueSx,
}: {
  label: string
  value?: string | number | null
  labelSx?: SxProps
  valueSx?: SxProps
}) => {
  return (
    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" width="100%" columnGap={3}>
      <Typography variant="bodySmall" fontFamily="GothamBook" sx={{ my: 0.5, ...labelSx }}>
        {label}
      </Typography>
      <Typography variant="bodySmall" fontFamily="GothamBook" sx={{ my: 0.5, ...valueSx }}>
        {value}
      </Typography>
    </Stack>
  )
}

export default ContentItem
