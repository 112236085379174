import { chartPallete } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import ConsumptionItem from '@components/ConsumptionItem/ConsumptionItem'
import ConsumptionSumSkeleton from '@components/Skeletons/ConsumptionSumSkeleton'
import { IAggregationMeasurements } from '@interfaces/measurements'
import { Stack, Divider, Paper, useMediaQuery, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IConsumptionSumProps {
  data?: IAggregationMeasurements | null
  unit: 'CHF' | 'kWh'
  isLoading?: boolean
}

const TextField = ({ text }: { text: string }) => {
  return (
    <Typography sx={{ my: 0 }} color={theme.palette.text.primary} fontFamily="GothamBook" variant="bodySmall">
      {text}
    </Typography>
  )
}

export const ConsumptionSum = ({ data, unit, isLoading }: IConsumptionSumProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileLarge = useMediaQuery(theme.breakpoints.down('md'))

  const sumHighRate = parseFloat(data?.sumHighRate ?? '0').toFixed(2)
  const sumLowRate = parseFloat(data?.sumLowRate ?? '0').toFixed(2)
  const sumTotal = parseFloat(data?.sumTotal ?? '0').toFixed(2)

  if (isLoading) return <ConsumptionSumSkeleton />

  return (
    <Paper
      variant={isMobile ? 'outlined' : 'elevation'}
      elevation={0}
      sx={{
        backgroundColor: 'transparent',
        borderRadius: 0,
        [theme.breakpoints.down('sm')]: {
          backgroundColor: theme.palette.grey[50],
          p: 1,
          mx: 0,
          borderRadius: 4,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent={isMobile ? 'space-evenly' : 'end'}
        divider={<Divider orientation="vertical" flexItem />}
        spacing={isMobileLarge ? 0.5 : 2}
      >
        <ConsumptionItem
          label={t('power_consumption_page.high_rate')}
          value={sumHighRate}
          unit={unit}
          showSquare={true}
          color={chartPallete.bar.highRate.fill}
          componentVariant="ConsumptionSum"
          showTooltip
          tooltipContent={
            <>
              <TextField text={t('power_consumption_page.monday_to_sunday')} />
              <TextField text={t('power_consumption_page.timerange_high_rate')} />
            </>
          }
        />
        <ConsumptionItem
          label={t('power_consumption_page.low_rate')}
          value={sumLowRate}
          unit={unit}
          showSquare={true}
          color={chartPallete.bar.lowRate.fill}
          componentVariant="ConsumptionSum"
          showTooltip
          tooltipContent={
            <>
              <TextField text={t('power_consumption_page.monday_to_sunday')} />
              <TextField text={t('power_consumption_page.timerange_low_rate')} />
            </>
          }
        />
        <ConsumptionItem
          label={t('power_consumption_page.total_consumption')}
          value={sumTotal}
          unit={unit}
          showSquare={false}
          componentVariant="ConsumptionSum"
          showTooltip
          tooltipContent={<TextField text={t('power_consumption_page.explenation_energy_consumption')} />}
        />
      </Stack>
    </Paper>
  )
}
