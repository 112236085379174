import dayjs from 'dayjs'

export const getFormattedBillingPeriod = (
  billingPeriodFromDate?: string | null,
  billingPeriodToDate?: string | null
): string => {
  const formatDate = (date?: string | null) => {
    return date ? dayjs(date).format('DD.MM.YY') : '?'
  }

  if (!billingPeriodFromDate && !billingPeriodToDate) {
    return ''
  }

  const fromDate = formatDate(billingPeriodFromDate)
  const toDate = formatDate(billingPeriodToDate)

  return `(${fromDate} - ${toDate})`
}
