import DtpErrorMessage from '@components/DtpErrorMessage/DtpErrorMessage'
import DtpModal from '@components/DtpModal/DtpModal'
import { useTheme } from '@mui/material'

interface IDtpErrorModalProps {
  errorTitle?: string
  errorDescription?: string
  open: boolean
  onClose: () => void
  onConfirm: () => void
  isLoading?: boolean
}

const DtpErrorModal = ({ errorDescription, errorTitle, open, onClose, onConfirm, isLoading }: IDtpErrorModalProps) => {
  const theme = useTheme()
  return (
    <DtpModal
      open={open}
      onClose={onClose}
      closeOutside
      aria-modal
      aria-hidden="false"
      sx={{
        '.MuiPaper-root': {
          padding: theme.spacing(3, 2.5),
          margin: 0,
          maxHeight: '100%',
          width: '100%',
          height: 'initial',
          [theme.breakpoints.down('md')]: {
            height: '100vh',
          },
        },
        '& .MuiBox-root:nth-of-type(2)': {
          height: '100%',
        },
      }}
    >
      <DtpErrorMessage
        errorTitle={errorTitle}
        errorDescription={errorDescription}
        onClose={onClose}
        onConfirm={onConfirm}
        isLoading={isLoading}
      />
    </DtpModal>
  )
}

export default DtpErrorModal
