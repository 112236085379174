import theme from '@assets/themes/theme'
import { IAddOn } from '@hooks/useLocations/useLocationsData'
import { InputBase, Select, SelectProps, Skeleton, Typography, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ArrowDownIcon } from '../../../assets/icons'
import DtpFormWrapper, { FormProps } from '../DtpFormWrapper/DtpFormWrapper'
import { ActiveMenuItem } from './components/ActiveMenuItem'
import { ActiveMenuSubItem } from './components/ActiveMenuSubItem'
import { ExpiredMenuItem } from './components/ExpiredMenuItem'

interface ISelectItem<T = IAddOn> {
  label: string
  value: string
  addOn?: T
}
export interface IMenuItemProps {
  item: ISelectItem<IAddOn>
  handleClick: (item: ISelectItem<IAddOn>) => void
}

export interface IDtpDropdownProps<T> extends FormProps {
  activeContracts: ISelectItem<IAddOn>[]
  expiredContracts: ISelectItem<IAddOn>[]
  placeholder?: string
  noDataLabel?: string
  isLoading?: boolean
  onLocationsSelect?: (item: ISelectItem<T | IAddOn>) => void
}

export function DtpContractDropdown<T>(props: IDtpDropdownProps<T> & SelectProps<string>) {
  const { t } = useTranslation()
  const {
    activeContracts,
    expiredContracts,
    value,
    helperText,
    required,
    label,
    error,
    fullWidth,
    onLocationsSelect,
    sx,
    isLoading,
    ...rest
  } = props
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClick = (item: ISelectItem<T | IAddOn>) => onLocationsSelect?.(item)

  if (isLoading) return <Skeleton sx={{ width: 200, height: 45 }} />

  return (
    <DtpFormWrapper label={label} helperText={helperText} error={error} fullWidth={fullWidth} required={required}>
      <Select
        value={value}
        variant={'standard'}
        input={<InputBase error={error} />}
        IconComponent={ArrowDownIcon}
        MenuProps={{
          slotProps: {
            paper: {
              sx: {
                '.MuiList-root': {
                  listStyle: 'disc',
                  width: isMobile ? '100%' : 340,
                },
              },
            },
          },
        }}
        sx={{
          width: isMobile ? '100%' : 340,
          p: 0,
          '.MuiSelect-select svg': {
            display: 'none',
          },
          ...sx,
        }}
        id="dropdownMenu"
        {...rest}
      >
        {activeContracts?.map((item) => {
          if (item.addOn?.contractId && item.addOn.contractId === '0') {
            return ActiveMenuItem({ item, handleClick })
          } else if (item.addOn?.contractId) {
            return ActiveMenuSubItem({ item, handleClick })
          }
        })}

        {expiredContracts.length && (
          <Typography variant="caption" sx={{ px: 2, borderTop: '1px solid #D5D8D5', paddingTop: 2 }}>
            {t('contracts.terminated_contracts')}
          </Typography>
        )}

        {expiredContracts?.map((item) => {
          if (item.addOn?.contractId) {
            return ExpiredMenuItem({ item, handleClick })
          }
        })}
      </Select>
    </DtpFormWrapper>
  )
}
