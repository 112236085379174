import theme from '@assets/themes/theme'
import LogoutIcon from '@mui/icons-material/Logout'
import { Button, ButtonProps, Typography, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../../../hooks/useAuth/useAuth'

export const LogoutButton = (props: ButtonProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { logout } = useAuth()
  const { fullWidth, sx } = props
  const { t } = useTranslation()
  return (
    <Button
      fullWidth={fullWidth}
      sx={sx}
      color="error"
      onClick={logout}
      disableTouchRipple
      variant="text"
      startIcon={<LogoutIcon sx={{ fill: 'error' }} />}
      {...props}
    >
      <Typography variant={isMobile ? 'bodyRegularEmphasized' : 'bodySmall'} width="100%" textAlign="left">
        {t('navigation.navi_logout')}
      </Typography>
    </Button>
  )
}
