import { palette } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import { Stack, Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

interface CurrentDate {
  currentDate?: string
}

const chartNoDataStyles = {
  borderTop: 1,
  borderColor: theme.palette.grey[300],
}

export const ChartNoData = (currentDate: CurrentDate) => {
  const { t } = useTranslation()

  const { currentDate: currentDateProp } = currentDate
  const isValidDate = dayjs(currentDateProp).isValid()

  const returnTranslationBasedOnDate = () => {
    if (isValidDate) {
      return t('power_consumption_page.error_message_no_data_available_description', {
        date: currentDateProp,
      })
    }
    return t('power_consumption_page.error_message_no_contracts_description')
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: 10, left: 0, right: 0 }}>
        <Stack justifyContent="center" alignItems="center">
          <Box
            sx={{
              textAlign: 'center',
              px: 4,
              py: 1,
              background: palette.background?.paper,
              [theme.breakpoints.down('sm')]: {
                mx: 2,
              },
            }}
          >
            <Typography variant="leadEmphasized">
              {t('power_consumption_page.error_message_no_data_available')}
            </Typography>
            <Typography
              variant="bodyRegular"
              sx={{
                fontWeight: 400,
                lineHeight: 1,
                width: isValidDate ? '100%' : '400px',
              }}
            >
              {returnTranslationBasedOnDate()}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Stack direction="column" justifyContent="space-evenly" spacing={7} pl={3} mt={3}>
        {Array.from({ length: 4 }, (_, index) => (
          <Box key={index} {...chartNoDataStyles} />
        ))}
      </Stack>
    </Box>
  )
}
