import React, { createContext, useState, useMemo } from 'react'

import appsettings from '../appsettings.json'

interface IFeatureFlags {
  [key: string]: boolean
}

interface IFeatureToggleContext {
  features: IFeatureFlags
}

export const FeatureToggleContext = createContext<IFeatureToggleContext>({ features: {} })

const FeatureToggleProvider = ({ children }: { children: React.ReactNode }) => {
  const [features] = useState<IFeatureFlags>(appsettings)

  const contextValues = useMemo(() => {
    return { features }
  }, [features])

  return <FeatureToggleContext.Provider value={contextValues}>{children}</FeatureToggleContext.Provider>
}

export default FeatureToggleProvider
