import useRoleAccess from '@hooks/useRoleAccess/useRoleAccess'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = () => {
  const { isAuthenticated, isEndUser } = useRoleAccess()

  return isEndUser && isAuthenticated ? <Outlet /> : <Navigate to="/" replace />
}

export default PrivateRoute
