import { ProsumerIcon } from '@assets/icons'
import { Typography, MenuItem, Stack } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'

import { IMenuItemProps } from '../DtpDropdown'

export const ActiveMenuItem = ({ item, handleClick }: Readonly<IMenuItemProps>) => {
  return (
    <MenuItem
      key={item.value}
      id={item.value}
      value={item.value}
      onClick={() => handleClick(item)}
      sx={{
        '&.MuiMenuItem-root': {
          py: 1.25,
          borderTop: '1px solid #D5D8D5',
        },
        '&.MuiMenuItem-root:nth-of-type(3)': {
          borderTop: '1px solid transparent',
        },
      }}
    >
      <Stack flexDirection="row" justifyContent="space-between" width="100%" alignItems="center">
        <Typography noWrap>{item.label}</Typography>
        <Show when={!!item.addOn?.isFeedInLocation}>
          <ProsumerIcon sx={{ width: '20px', height: '20px' }} />
        </Show>
      </Stack>
    </MenuItem>
  )
}
