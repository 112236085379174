import theme from '@assets/themes/theme'
import { Table, TableBody, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { CustomTableCell } from './DtpPaidInvoiceAccordionUtills'

const InvoiceTable = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()

  const columns = [
    { label: t('invoices.invoice_date') },
    { label: t('invoices.invoice_number') },
    { label: t('invoices.invoice_amount') },
    { label: t('invoices.invoice_document') },
  ]

  return (
    <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 24px' }}>
      <TableHead>
        <TableRow>
          {columns.map(({ label }) => (
            <CustomTableCell key={label}>
              <Typography variant="bodySmallEmphasized" color={theme.palette.text.secondary}>
                {label}
              </Typography>
            </CustomTableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  )
}

export default InvoiceTable
