import { skeletonPalette } from '@assets/themes/palette'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import { Box, Card, CardContent, Grid, Skeleton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface CompareCardSkeletonProps {
  chartType: 'month' | 'year'
  fullWidth?: boolean
}

interface SkeletonBarProps {
  height: number
}

const GridColumnBar = ({ height }: SkeletonBarProps) => (
  <Grid container item xs display="flex" direction={'column'} alignItems={'center'}>
    <SkeletonSelect />
    <Grid item container xs direction={'column'} alignItems={'center'} justifyContent={'end'}>
      <Grid item>
        <SkeletonBar height={height} />
      </Grid>
      <Grid item>
        <SkeletonText />
      </Grid>
    </Grid>
  </Grid>
)

const SkeletonColor = skeletonPalette.fill

const SkeletonSelect = () => (
  <Skeleton
    variant="rectangular"
    height={44}
    style={{ width: '100%', maxWidth: '180px' }}
    sx={{ bgcolor: SkeletonColor, my: 2 }}
  />
)
const SkeletonBar = ({ height }: SkeletonBarProps) => (
  <Skeleton variant="rectangular" width={41} height={height} sx={{ bgcolor: SkeletonColor }} />
)
const SkeletonText = () => (
  <Skeleton variant="rectangular" width={60} height={16} sx={{ bgcolor: SkeletonColor, mt: 2 }} />
)

const CompareCardSkeleton = ({ chartType, fullWidth }: CompareCardSkeletonProps) => {
  const { t } = useTranslation()

  return (
    <Card
      sx={{
        flexGrow: 1,
        height: '378px',
        minWidth: fullWidth ? '100%' : 'auto',
        maxWidth: '620px',
        px: { xs: 1, sm: 2, md: 4 },
        py: 2,
        border: `1px solid ${skeletonPalette.border}`,
        borderRadius: '16px',
        boxShadow: 'none',
      }}
    >
      <CardContent sx={{ py: 0 }}>
        <Typography variant="h5">
          {chartType === 'month'
            ? t('power_consumption_page.monthly_comparison')
            : t('power_consumption_page.yearly_comparison')}
        </Typography>
        <Grid container minHeight={'290px'} position={'relative'}>
          <GridColumnBar height={112} />
          <Grid item px={{ xs: 1, sm: 2, md: 4 }} py={3}>
            <SyncAltIcon />
          </Grid>
          <GridColumnBar height={127} />
          <Box
            sx={{
              position: 'absolute',
              borderTop: `1px solid ${SkeletonColor}`,
              bottom: 0,
              height: '32px',
              width: '100%',
            }}
          />
        </Grid>
      </CardContent>
    </Card>
  )
}

export default CompareCardSkeleton
