import { IAccount } from '@interfaces/accounts'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface IAccountStore {
  accounts: IAccount[]
  activeAccount: {
    gpnr: string
    name: string
  }
  setActiveAccount: (account: IAccount) => void
  setAccounts: (accounts: IAccount[]) => void
  resetActiveAccount: () => void
}

const initialAccount = {
  gpnr: '',
  name: '',
}

export const useAccountStore = create<IAccountStore>()(
  persist(
    (set) => ({
      accounts: [],
      activeAccount: initialAccount,
      setActiveAccount: (account) => set({ activeAccount: account }),
      setAccounts: (accounts) => set({ accounts }),
      resetActiveAccount: () => set({ activeAccount: initialAccount, accounts: [] }),
    }),
    {
      name: 'accounts-store',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
