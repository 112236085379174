import { ISelectItem } from '@components/DtpDropdown/DtpDropdown'
import { IAddOn } from '@hooks/useLocations/useLocationsData'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

interface ILocationStore {
  selectedItem: ISelectItem<IAddOn>
  showExpiredContracts: boolean
  setExpiredContracts: (showExpiredContracts: boolean) => void
  setSelectedItem: (selectedLocation: ISelectItem<IAddOn> | undefined) => void
  resetLocations: () => void
}

const initialLocation = {
  label: '',
  value: '',
  addOn: {
    gpnr: '',
    supplyStartDate: '',
    supplyEndDate: '',
    meterId: '',
    locationId: '',
  },
}

export const useLocationStore = create<ILocationStore>()(
  persist(
    (set) => ({
      showExpiredContracts: false,
      selectedItem: initialLocation,
      setExpiredContracts: (showExpiredContracts) => set({ showExpiredContracts: showExpiredContracts }),
      setSelectedItem: (selectedLocation) => set({ selectedItem: selectedLocation }),
      resetLocations: () => set({ selectedItem: initialLocation, showExpiredContracts: false }),
    }),
    { name: 'location-store', storage: createJSONStorage(() => sessionStorage) }
  )
)
