import { ProfileIcon } from '@assets/icons'
import AccountsAccordion from '@components/AccountsAccordion/AccountsAccordion'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { useAuth } from '@hooks/useAuth/useAuth'
import useRoleAccess from '@hooks/useRoleAccess/useRoleAccess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Menu, MenuItem, Typography, Skeleton, styled, Divider, Fade, useTheme } from '@mui/material'
import { profileRoutes, settingsRoutes } from '@src/router/routes.config'
import FeatureToggle from '@src/ui/wrappers/FeatureToggle/FeatureToggle'
import Show from '@src/ui/wrappers/Show/Show'
import { t } from 'i18next'
import { useState, MouseEvent } from 'react'
import { NavLink } from 'react-router-dom'

import { LogoutButton } from '../LogoutButton/LogoutButton'

const CustomMenuButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.25, 1.5),
  '& .MuiTypography-root': {
    margin: 0,
  },
  '&.active': {
    background: theme.palette.primary.light,
  },
  '&:hover': {
    background: theme.palette.grey[50],
    '&.active': {
      background: theme.palette.primary.light,
    },
  },
}))

export const CustomMenuItem = styled(NavLink)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  width: '100%',
  background: 'transparent',
  padding: theme.spacing(1, 4),
  color: '#303636',
  textDecoration: 'none',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 15,
    top: 17,
    background: 'transparent',
    width: 5,
    height: 5,
    borderRadius: '100%',
    transition: 'all 0.3s ease',
  },
  '&.active': {
    color: theme.palette.primary.main,
    '&::before': {
      background: theme.palette.primary.main,
    },
  },
  '&:hover': {
    '&::before': {
      background: theme.palette.text.primary,
    },
  },
}))

const ProfileMenu = () => {
  const { user, getKeycloakAccountPage } = useAuth()
  const { accounts, activeAccount, switchAccount, isLoading } = useAccounts()
  const theme = useTheme()
  const { disabledStyles, isEndUser } = useRoleAccess()

  const [anchorButton, setAnchorButton] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorButton)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorButton(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorButton(null)
  }
  const isOnProfilePages = profileRoutes.some(({ path }) => path === window.location.pathname)
  const isOnSettingsPages = settingsRoutes.some(({ path }) => path === window.location.pathname)

  const background =
    isOnProfilePages || isOnSettingsPages ? theme.palette.primary.light : isOpen ? '#EFF0EF' : 'transparent'

  const handleAccountInfoRedirect = (e: React.SyntheticEvent) => {
    e.preventDefault()
    getKeycloakAccountPage()
  }

  if (isLoading) return <Skeleton sx={{ borderRadius: 99 }} variant="rounded" width={160} height={44} />

  return (
    <>
      <CustomMenuButton
        sx={{ background }}
        id="menuButton"
        variant="text"
        aria-controls={'profileMenu'}
        className={isOnProfilePages || isOnSettingsPages ? 'active' : ''}
        aria-haspopup={'true'}
        aria-expanded={'true'}
        onClick={handleClick}
        disableTouchRipple
        startIcon={<ProfileIcon />}
        endIcon={<ExpandMoreIcon sx={{ transform: isOpen ? 'rotate(180deg)' : '' }} />}
      >
        <Show when={isEndUser}>
          <Typography color={'inherit'} noWrap component={'span'}>
            {activeAccount && activeAccount.name ? activeAccount.name : user?.email}
          </Typography>
        </Show>
        <Show when={!isEndUser}>
          <Typography color={'inherit'} noWrap component={'span'}>
            {t('navigation.navi_profile')}
          </Typography>
        </Show>
      </CustomMenuButton>
      <Menu
        id="profileMenu"
        open={isOpen}
        anchorEl={anchorButton}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'menuButton',
        }}
        TransitionComponent={Fade}
      >
        <Typography fontFamily="GothamBook" variant="caption" mx={4}>
          {t('navigation.navi_my_profile')}
        </Typography>
        {profileRoutes.map(({ path, name }, index) => (
          <CustomMenuItem key={index} to={path} onClick={handleClose} sx={disabledStyles}>
            {t(name)}
          </CustomMenuItem>
        ))}
        <Typography fontFamily="GothamBook" variant="caption" mx={4}>
          {t('navigation.navi_settings')}
        </Typography>
        <CustomMenuItem to="/login-security" onClick={handleAccountInfoRedirect}>
          {t('login_security')}
        </CustomMenuItem>
        {settingsRoutes.map(({ path, name }, index) => (
          <CustomMenuItem key={index} to={path} onClick={handleClose} sx={disabledStyles}>
            {t(name)}
          </CustomMenuItem>
        ))}

        <Show when={accounts && accounts.length > 1}>
          <AccountsAccordion
            currentAccount={activeAccount}
            accounts={accounts}
            switchAccount={switchAccount}
            onClick={handleClose}
          />
        </Show>
        <FeatureToggle flag="storybook">
          <Typography fontFamily="GothamBook" variant="caption" mx={4}>
            Storybook
          </Typography>
          <CustomMenuItem id="storybook" to="/storybook" target="_blank">
            Storybook
          </CustomMenuItem>
        </FeatureToggle>
        <Divider sx={{ my: 1.5 }} />
        <MenuItem>
          <LogoutButton
            sx={{
              marginLeft: 0,
              width: '100%',
              justifyContent: 'flex-start',
              padding: '0 19px',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          />
        </MenuItem>
      </Menu>
    </>
  )
}

export default ProfileMenu
