import { replaceEmptyStringsWithNull, isNil } from '@helpers/functional'
import useNotification from '@hooks/useNotification/useNotification'
import { IAccountDetails } from '@interfaces/accounts'
import CancelIcon from '@mui/icons-material/Cancel'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import NorthEastIcon from '@mui/icons-material/NorthEast'
import { Typography, Box, Stack, IconButton, Divider, Button, Link } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { accountDetailsSchema } from '@src/validations/accountDetailsSchema'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AccountForm from './AccountForm'
import { ContactInfoField } from './ContactInfoField'

export interface IDtpPersonalDataFieldsProps extends IAccountDetails {
  updateAccountDetails: (
    data: IAccountDetails,
    options: {
      onSuccess: (data: IAccountDetails) => void
      onError: (error: Error) => void
    }
  ) => void
  refetchAccountDetails: () => void
  refetchAccounts: () => void
  isUpdatingAccountDetails: boolean
}
const DtpPersonalDataFields = ({
  accountType,
  customerName,
  companyAddition,
  firstName1,
  lastName1,
  firstName2,
  lastName2,
  mobile,
  landline,
  email,
  address,
  postalCode,
  city,
  updateAccountDetails,
  refetchAccounts,
  refetchAccountDetails,
  isUpdatingAccountDetails,
}: IDtpPersonalDataFieldsProps) => {
  const { t } = useTranslation()
  const notification = useNotification()

  const [isEditing, setIsEditing] = useState(false)

  const handleOnUpdateSuccess = () => {
    refetchAccounts()
    refetchAccountDetails()
    setIsEditing(false)
  }

  const initialValues = {
    accountType: accountType,
    customerName: customerName,
    companyAddition: companyAddition,
    firstName1: firstName1,
    lastName1: lastName1,
    firstName2: firstName2,
    lastName2: lastName2,
    mobile: mobile,
    landline: landline,
    email: email,
    address: address,
    postalCode: postalCode,
    city: city,
  }

  const handleOnSubmit = async (accountDetails: IAccountDetails) => {
    return updateAccountDetails(replaceEmptyStringsWithNull(accountDetails), {
      onSuccess: handleOnUpdateSuccess,
      onError: () => {
        notification('error', t('http_errors.saving_error_title'), t('http_errors.saving_error_description'))
      },
    })
  }

  const { handleChange, handleSubmit, values, setFieldValue, errors, resetForm, dirty } = useFormik({
    initialValues,
    validationSchema: accountDetailsSchema[accountType],
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: handleOnSubmit,
  })
  const handleShowForm = () => setIsEditing((prev) => !prev)

  const handleCancel = () => {
    setIsEditing(false)
    resetForm()
  }

  const isSubmitingDisabled = !dirty || Object.keys(errors).length > 0

  return (
    <Box>
      <Typography variant="heading3" sx={{ mt: { xs: 1.5, md: 6 }, mb: 4 }}>
        {t('personal_data.personal_data')}
      </Typography>
      <Box maxWidth="679px">
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography sx={{ my: 0 }} variant="leadEmphasized">
              {t('personal_data.personal_data_contact')}
            </Typography>
            <Show when={!isEditing}>
              <IconButton sx={{ height: '42px' }} onClick={handleShowForm}>
                <EditOutlinedIcon />
              </IconButton>
            </Show>
            <Show when={isEditing}>
              <Button
                variant="text"
                size="small"
                startIcon={<CancelIcon />}
                color="inherit"
                sx={{ color: '#4F544F', justifyContent: 'flex-end', minWidth: 'auto' }}
                onClick={handleCancel}
              >
                {t('cancel')}
              </Button>
            </Show>
          </Stack>
          <Divider />
          <Show when={!isEditing}>
            <Stack gap={1.5} mt={2}>
              <Show when={accountType === 'Person'}>
                <ContactInfoField
                  title={t('personal_data.personal_data_name')}
                  content={`${firstName1} ${lastName1}`}
                  additionalContent={isNil(firstName2) ? '' : `${firstName2} ${lastName2}`}
                />
              </Show>
              <Show when={accountType === 'Organisation'}>
                <ContactInfoField
                  title={t('personal_data.personal_data_company_name')}
                  content={customerName}
                  additionalContent={companyAddition}
                />
              </Show>
              <ContactInfoField
                title={`${t('personal_data.personal_data_telephone')} (${t('personal_data.landline')})`}
                content={landline}
                emptyContentMessage={t('personal_data.not_specified')}
              />
              <ContactInfoField
                title={`${t('personal_data.personal_data_telephone')} (${t('personal_data.mobile')})`}
                content={mobile}
                emptyContentMessage={t('personal_data.not_specified')}
              />
              <ContactInfoField
                title={t('personal_data.personal_data_email')}
                content={email}
                emptyContentMessage={t('personal_data.not_specified')}
              />
            </Stack>
          </Show>
          <Show when={isEditing}>
            <AccountForm
              accountType={accountType}
              onChange={handleChange}
              onSubmit={handleSubmit}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
              firstName1={firstName1}
              customerName={customerName}
              companyAddition={companyAddition}
              disabled={isSubmitingDisabled}
              isUpdatingAccountDetails={isUpdatingAccountDetails}
            />
          </Show>
        </Box>
        <Box mt={4}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography sx={{ my: 0 }} variant="leadEmphasized">
              {t('personal_data.personal_data_address')}
            </Typography>
            <Button
              component={Link}
              sx={{ p: { xs: 0 }, textDecoration: 'underline' }}
              variant="text"
              endIcon={<NorthEastIcon />}
              href="https://www.ckw.ch/kontakt/umzug"
              target="_blank"
              rel="noopener"
            >
              {t('personal_data.personal_data_report_relocation')}
            </Button>
          </Stack>
          <Divider />
          <Stack gap={1.5} mt={2}>
            <ContactInfoField title={t('personal_data.personal_data_street')} content={address} />
            <ContactInfoField title={t('personal_data.personal_data_postal_code')} content={postalCode} />
            <ContactInfoField title={t('personal_data.personal_data_city')} content={city} />
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default DtpPersonalDataFields
