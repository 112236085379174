import { ExtendedDateView } from '@components/ChartControl/ChartControl'
import { AggregationEnum } from '@enums/aggregationTypes'
import { IContract, IContractLocation } from '@interfaces/contracts'
import { DateView } from '@mui/x-date-pickers'
import dayjs, { Dayjs, OpUnitType } from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'

dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export const formatTimestamp = (timestamp: string): string => {
  return dayjs(timestamp).format('HH:mm')
}

export const getTodayAndYesterday = (date: Dayjs): { isItYesterday: boolean; isItToday: boolean } => {
  const isItToday: boolean = date.isToday()
  const isItYesterday: boolean = date.isYesterday()

  return {
    isItToday,
    isItYesterday,
  }
}
export const getMinMaxDateFromContract = (
  contractStartDate: Dayjs,
  lastAvailableDay: Dayjs
): { minDate: Dayjs; maxDate: Dayjs } => {
  const contractStartDateYear = contractStartDate.format('YYYY')
  const lastAvailableDayYear = lastAvailableDay.format('YYYY')

  if (!contractStartDate.isValid())
    return {
      minDate: dayjs(),
      maxDate: lastAvailableDay,
    }

  if (parseInt(lastAvailableDayYear) - parseInt(contractStartDateYear) > 10) {
    return {
      minDate: lastAvailableDay.subtract(10, 'year'),
      maxDate: lastAvailableDay,
    }
  }
  return {
    minDate: contractStartDate,
    maxDate: lastAvailableDay,
  }
}

export const validateChangeDate = (
  startDate: Dayjs,
  endDate: Dayjs,
  viewType: DateView
): { isAfterLastAvailableDay: boolean; isBeforeLastAvailableDay: boolean; isSameDates: boolean } => {
  const isBeforeLastAvailableDay = dayjs(startDate).isSameOrBefore(endDate)
  const isAfterLastAvailableDay = dayjs(startDate).isSameOrAfter(endDate)
  const isSameDates = dayjs(startDate).isSame(endDate)
  const dateFormatYear = viewType === 'year' ? 'YYYY' : 'YYYY-MM-DD'
  const dateFormat = viewType === 'month' ? 'MMM YYYY' : dateFormatYear

  switch (viewType) {
    case 'month':
    case 'year':
      return {
        isAfterLastAvailableDay: dayjs(startDate).format(dateFormat) === dayjs(endDate).format(dateFormat),
        isBeforeLastAvailableDay: dayjs(startDate).format(dateFormat) === dayjs(endDate).format(dateFormat),
        isSameDates: isSameDates,
      }
    default:
      return {
        isAfterLastAvailableDay: isAfterLastAvailableDay,
        isBeforeLastAvailableDay: isBeforeLastAvailableDay,
        isSameDates: isSameDates,
      }
  }
}

export const isDateValid = (date: string) => dayjs(date).isValid()

export const isValidModalDates = (startDate: string, endDate: string, startSupplyDate?: string) => {
  const minDate = dayjs().subtract(10, 'year').isBefore(startSupplyDate)
    ? startSupplyDate
    : dayjs().subtract(10, 'year')
  const maxDate = dayjs().subtract(1, 'day')

  if (
    !dayjs(startDate).isValid() ||
    dayjs(startDate).isBefore(minDate) ||
    dayjs(startDate).isAfter(maxDate) ||
    !dayjs(endDate).isValid() ||
    dayjs(endDate).isBefore(dayjs(startDate)) ||
    dayjs(endDate).isAfter(maxDate)
  ) {
    return false
  }

  return true
}

export const getAggregationsStartEndDate = (
  inputDate: string,
  chartType: OpUnitType,
  customAggregation?: boolean,
  customStartDate?: string,
  customEndDate?: string
) => {
  if (customAggregation && customStartDate?.length && customEndDate?.length) {
    return { startDate: customStartDate, endDate: customEndDate }
  }
  const startDate = dayjs(inputDate).startOf(chartType).format('YYYY-MM-DD')
  const endDate = dayjs(inputDate).endOf(chartType).format('YYYY-MM-DD')

  return { startDate, endDate }
}

export const getAggregationType = (startDateValue: string, endDateValue: string): AggregationEnum => {
  const startDate = dayjs(startDateValue)
  const endDate = dayjs(endDateValue)
  let aggregationType
  if (startDate.isSame(endDate)) {
    aggregationType = AggregationEnum.HOUR
  } else if (endDate.diff(startDate, 'days') < 31) {
    aggregationType = AggregationEnum.DAY
  } else if (startDate.add(1, 'year').isAfter(endDate)) {
    aggregationType = AggregationEnum.MONTH
  } else {
    aggregationType = AggregationEnum.YEAR
  }
  return aggregationType
}
// helper function for getDefaultMinMaxDateFromContract
export const returnMaxDateTemp = (
  viewType: ExtendedDateView,
  lastAvailableData: string,
  contractEndDate: string,
  dateFormat: string,
  isItAfterContractEndDate: boolean,
  isItFirstOfAMonth: boolean,
  isItStartOfAYear: boolean
): string => {
  const returnMaxDateTempIfIsAfterContractEndDate = isItAfterContractEndDate
    ? dayjs(contractEndDate).format(dateFormat)
    : dayjs().format(dateFormat)

  if (viewType === 'day')
    return lastAvailableData && lastAvailableData.length > 0 && !isItAfterContractEndDate
      ? lastAvailableData
      : returnMaxDateTempIfIsAfterContractEndDate

  if (viewType === 'month')
    return isItFirstOfAMonth && !isItAfterContractEndDate
      ? dayjs().subtract(1, viewType).format(dateFormat)
      : returnMaxDateTempIfIsAfterContractEndDate

  if (viewType === 'year')
    return isItStartOfAYear && !isItAfterContractEndDate
      ? dayjs().subtract(1, viewType).format(dateFormat)
      : returnMaxDateTempIfIsAfterContractEndDate

  return dayjs().format(dateFormat)
}

export const getDefaultMinMaxDateFromContract = (
  viewType: ExtendedDateView,
  lastAvailableData: string,
  contractEndDate: string,
  contractStartDate: string,
  dateFormat: string
): { maxDate: string; minDate: string } => {
  const isItFirstOfAMonth = dayjs().date() === 1
  const isItAfterContractEndDate = dayjs().isAfter(dayjs(contractEndDate))
  const isItStartOfAYear = dayjs().month() === 0 && isItFirstOfAMonth
  const returnMinDate = (maxDate: string) =>
    getMinMaxDateFromContract(dayjs(contractStartDate), dayjs(maxDate)).minDate.format(dateFormat)
  const maxDateTemp = returnMaxDateTemp(
    viewType,
    lastAvailableData,
    contractEndDate,
    dateFormat,
    isItAfterContractEndDate,
    isItFirstOfAMonth,
    isItStartOfAYear
  )
  return {
    maxDate: maxDateTemp,
    minDate: returnMinDate(maxDateTemp),
  }
}

export const getDefaultComparationFromContract = (
  contractStartDate: string,
  contractEndDate: string,
  maxDate: string
): {
  firstPeriod: string
  secondPeriod: string
} => {
  const dateFormat = 'YYYY-MM-DD'
  const lastAvailableDay = maxDate.length ? maxDate : dayjs().format(dateFormat)
  const contractIsValid = dayjs(contractEndDate).isAfter(dayjs(lastAvailableDay))
  const isContractOneYearValid = dayjs(contractStartDate)
    .add(1, 'year')
    .isSameOrBefore(dayjs(contractIsValid ? lastAvailableDay : contractEndDate))

  const isItFirstOfAMonth = dayjs(lastAvailableDay).date() === 1
  const isItStartOfAYear = dayjs(lastAvailableDay).month() === 0 && isItFirstOfAMonth
  if (isContractOneYearValid && contractIsValid) {
    if (isItFirstOfAMonth || isItStartOfAYear) {
      return {
        firstPeriod: dayjs(lastAvailableDay).format(dateFormat),
        secondPeriod: dayjs(lastAvailableDay).subtract(1, 'year').format(dateFormat),
      }
    }
    return {
      firstPeriod: dayjs(lastAvailableDay).format(dateFormat),
      secondPeriod: dayjs(lastAvailableDay).subtract(1, 'year').format(dateFormat),
    }
  } else if (!contractIsValid && isContractOneYearValid) {
    return {
      firstPeriod: dayjs(contractEndDate).format(dateFormat),
      secondPeriod: dayjs(contractEndDate).subtract(1, 'year').format(dateFormat),
    }
  } else if (contractIsValid && !isContractOneYearValid) {
    return {
      firstPeriod: dayjs(lastAvailableDay).format(dateFormat),
      secondPeriod: dayjs(contractStartDate).format(dateFormat),
    }
  }

  return {
    firstPeriod: dayjs(contractEndDate).format(dateFormat),
    secondPeriod: dayjs(contractStartDate).format(dateFormat),
  }
}

//display year for home screen power consumption
export const calculateAvailableYear = (contractEndDate: string | undefined): string => {
  const dateFormat = 'YYYY'
  const isContractValid = dayjs(contractEndDate).isSameOrAfter(dayjs().format('YYYY-MM-DD'))
  const isFirstYanuary = dayjs().date() === 1 && dayjs().month() === 0
  if (!isContractValid) {
    return dayjs(contractEndDate).format(dateFormat)
  }
  if (isContractValid && isFirstYanuary) {
    return dayjs().subtract(1, 'year').format(dateFormat)
  }
  return dayjs().format(dateFormat)
}

export const calculateStartEndDateForLocation = (
  locations: IContractLocation[],
  locationId: string
): { startDate: string; endDate: string } => {
  const location = locations.find((location) => location.id === locationId)
  if (!location) return { startDate: '', endDate: '' }
  const meters = location.contracts
  if (!meters.length) return { startDate: '', endDate: '' }
  if (meters.length === 1) return { startDate: meters[0].supplyStartDate, endDate: meters[0].supplyEndDate }
  const startDateMeter = meters.reduce((prevMeter: IContract, currentMeter: IContract) => {
    return dayjs(currentMeter.supplyStartDate).isBefore(dayjs(prevMeter.supplyStartDate)) ? currentMeter : prevMeter
  })
  const endDateMeter = meters.reduce((prevMeter: IContract, currentMeter: IContract) => {
    return dayjs(currentMeter.supplyEndDate).isAfter(dayjs(prevMeter.supplyEndDate)) ? currentMeter : prevMeter
  })

  return { startDate: startDateMeter.supplyStartDate, endDate: endDateMeter.supplyEndDate }
}
