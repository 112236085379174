import { Link, Typography } from '@mui/material'
import InvoiceSettingsWrapper from '@src/ui/wrappers/InvoiceSettingsWrapper/InvoiceSettingsWrapper'
import { useTranslation } from 'react-i18next'

const ManualPayment = () => {
  const { t } = useTranslation()

  return (
    <InvoiceSettingsWrapper
      buttonTitle={t('back')}
      title={t('manual_payment.title')}
      link={t('navigation_url.invoices_settings')}
    >
      <Typography variant="bodyRegular" maxWidth={680}>
        {t('manual_payment.pay_manualy_description_1')}
      </Typography>

      <Typography variant="bodyRegular" maxWidth={680}>
        {t('manual_payment.pay_manualy_description_2')}
        <Link
          href={'https://www.postfinance.ch/de/unternehmen/produkte/preise-und-konditionen.html'}
          target="_blank"
          color="primary"
          fontFamily="GothamMedium"
          px={1}
        >
          {t('manual_payment.pay_manualy_description_3')}
        </Link>
        {t('manual_payment.pay_manualy_description_4')}
      </Typography>
    </InvoiceSettingsWrapper>
  )
}

export default ManualPayment
