import { MenuItem, Typography, Box } from '@mui/material'
import dayjs from 'dayjs'

import { IMenuItemProps } from '../DtpContractDropdown'

const contractPeriod = (startDate?: string, endDate?: string) =>
  `${dayjs(startDate).format('DD.MM.YYYY')} - ${dayjs(endDate).format('DD.MM.YYYY')}`

export const ExpiredMenuItem = ({ item, handleClick }: IMenuItemProps) => {
  return (
    <MenuItem
      key={item.value}
      id={item.value}
      value={item.value}
      onClick={() => handleClick?.(item)}
      sx={{
        '&.MuiMenuItem-root': {
          display: 'list-item',
          pl: 2,
          my: 1,
        },
      }}
    >
      <Box>
        <Typography noWrap fontWeight={500} fontFamily={'GothamMedium'}>
          {item.label}
        </Typography>
        <Typography noWrap fontFamily={'GothamBook'} fontWeight={400}>
          {contractPeriod(item.addOn?.supplyStartDate, item.addOn?.supplyEndDate)}
        </Typography>
      </Box>
    </MenuItem>
  )
}
