import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import translationDE from './de/translation.json'
import translationEN from './en/translation.json'

const resources = {
  en: { translation: translationEN },
  de: { translation: translationDE },
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    resources,
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
