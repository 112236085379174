import { activeMenuSubItemStyles } from '@components/DtpDropdown/components/ActiveMenuSubItem'
import { MenuItem, Typography } from '@mui/material'

import { IMenuItemProps } from '../DtpContractDropdown'

export const ActiveMenuSubItem = ({ item, handleClick }: Readonly<IMenuItemProps>) => {
  return (
    <MenuItem
      key={item.value}
      id={item.value}
      value={item.value}
      onClick={() => handleClick?.(item)}
      sx={activeMenuSubItemStyles}
    >
      <Typography noWrap sx={{ pl: 0 }}>
        {item.label}
      </Typography>
    </MenuItem>
  )
}
