import { downloadInvoice } from '@api/mutations'
import { downloadFile } from '@helpers/downloadCSVFunctions'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { useMutation } from '@tanstack/react-query'

export const useDownloadInvoice = (onSuccess?: () => void, onError?: () => void) => {
  const { activeAccount } = useAccounts()

  const mutation = useMutation({
    mutationFn: (invoiceId: string) =>
      downloadInvoice({
        gpnr: activeAccount.gpnr,
        invoiceId: invoiceId,
      }),
    onSuccess: (data: File, invoiceId: string) => {
      downloadFile({
        data: data,
        fileName: `${invoiceId}.pdf`,
        fileType: 'application/pdf',
      })
      onSuccess && onSuccess()
    },
    onError: () => onError && onError(),
  })

  return { ...mutation }
}
