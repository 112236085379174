import { chartPallete, prosumerPalette } from '@assets/themes/palette'
import { ChartNoData } from '@components/ChartNoData/ChartNoData'
import { ChartTooltip } from '@components/ChartTooltip/ChartTooltip'
import ChartSkeleton from '@components/Skeletons/ChartSkeleton'
import { handleSetTooltipPositionAndOpenArea, calculateMaxMinDomain, calculateTicks } from '@helpers/chartAreaFunctions'
import { formatTimestamp } from '@helpers/dateFunctions'
import { IMeasurement } from '@interfaces/measurements'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import { useState } from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Label, Tooltip } from 'recharts'

interface IChartAreaProps {
  data?: IMeasurement[]
  unit: 'CHF' | 'kWh'
  isFeedIn?: boolean
  noDataDateValue?: string
  isLoading?: boolean
  setTooltipPosition?: CallableFunction
  tooltipPosition: {
    x: number
    y: number
  }
}

const ChartArea = ({
  data = [],
  unit,
  noDataDateValue,
  isLoading,
  isFeedIn,
  tooltipPosition,
  setTooltipPosition,
}: IChartAreaProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const tickFormatter = (value: string) => formatTimestamp(value)
  const isSmallMobile = useMediaQuery(theme.breakpoints.between('xs', 500))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'))
  const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth)
  const TOOLTIP_INITIAL_X = isMobile ? 0 : 500
  const TOOLTIP_INITIAL_Y = isMobile ? 0 : 10
  const wrapperStylePositionTooltip = { left: 40, top: 0 }

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const chartStyles = {
    fontSize: isMobile ? 11 : 14,
    lineHeight: 21,
    stroke: chartPallete.area.axisColor,
    tickLine: false,
    tick: { stroke: 'transparent' },
    axisLine: { stroke: 'transparent' },
    fontFamily: 'Montserrat',
  }

  const { min, max } = calculateMaxMinDomain(data)
  const ticks = calculateTicks(min, max, 4)

  if (isLoading) return <ChartSkeleton chartAxis="ChartArea" />
  if (!data.length) return <ChartNoData currentDate={dayjs(noDataDateValue).format('DD. MMMM YYYY')} />

  return (
    <ResponsiveContainer height={296}>
      <AreaChart
        data={data}
        margin={{ top: 50, bottom: 50, left: 0, right: isMobile ? 0 : 25 }}
        onClick={(e: { chartX?: number; chartY?: number }) =>
          handleSetTooltipPositionAndOpenArea({
            e,
            isMobile,
            isTablet,
            isSmallMobile,
            viewportWidth,
            tooltipOpen,
            TOOLTIP_INITIAL_X,
            TOOLTIP_INITIAL_Y,
            setTooltipOpen,
            setTooltipPosition,
          })
        }
      >
        <defs>
          <linearGradient id="prosumerGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="-0.006" stopColor="rgba(253, 195, 0, 0.8)" />
            <stop offset="1" stopColor="rgba(253, 195, 0, 0)" />
          </linearGradient>
        </defs>

        <CartesianGrid stroke={chartPallete.area.gridStroke} strokeWidth={1} strokeDasharray="0" vertical={false} />

        <XAxis
          tickMargin={15}
          dataKey="timestampStartOfPeriod"
          tickFormatter={tickFormatter}
          interval={isMobile ? 3 : 1}
          {...chartStyles}
        />

        <YAxis ticks={ticks} domain={[min, max]} {...chartStyles}>
          <Label style={{ fontSize: isMobile ? 11 : 14 }} dy={-110} dx={18}>
            {unit}
          </Label>
        </YAxis>

        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={
            tooltipPosition.x !== TOOLTIP_INITIAL_X || isSmallMobile ? (
              <ChartTooltip
                tooltipPosition={tooltipPosition.x}
                setTooltipOpen={setTooltipOpen}
                unit={unit}
                chartOrigin={'ChartArea'}
              />
            ) : undefined
          }
          position={tooltipPosition}
          allowEscapeViewBox={{ x: false, y: true }}
          trigger={'click'}
          active={tooltipOpen}
          animationDuration={300}
          wrapperStyle={isSmallMobile ? wrapperStylePositionTooltip : undefined}
        />

        {!isFeedIn && (
          <Area
            type="monotone"
            dataKey="sumOfRates"
            stroke={chartPallete.area.stroke}
            strokeWidth={2}
            fill={chartPallete.area.area}
            style={{ cursor: 'pointer' }}
          />
        )}

        <Area
          type="monotone"
          dataKey="consumption"
          stroke={chartPallete.area.stroke}
          strokeWidth={2}
          fill={chartPallete.area.area}
          style={{ cursor: 'pointer' }}
        />

        <Area
          type="monotone"
          dataKey="feedIn"
          stroke={prosumerPalette.prosumerFeedIn}
          strokeWidth={2}
          fill={'url(#prosumerGradient)'}
          style={{ cursor: 'pointer' }}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default ChartArea
