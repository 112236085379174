import DtpPersonalDataFields from '@components/DtpPersonalDataFields/DtpPersonalDataFields'
import { SuspenseLoading } from '@components/SuspenseLoading/SuspenseLoading'
import { useAccountDetails } from '@hooks/useAccountDetails/useAccountDetails'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { Container } from '@mui/material'

const PersonalData = () => {
  const { refetch, isLoading: isLoadingAccounts } = useAccounts()
  const { accountData, isLoading, updateAccountDetails, refetchAccountDetails, isUpdatingAccountDetails } =
    useAccountDetails()

  if (isLoading || isLoadingAccounts) return <SuspenseLoading />

  return (
    <Container>
      <DtpPersonalDataFields
        {...accountData}
        updateAccountDetails={updateAccountDetails}
        refetchAccountDetails={refetchAccountDetails}
        refetchAccounts={refetch}
        isUpdatingAccountDetails={isUpdatingAccountDetails}
      />
    </Container>
  )
}

export default PersonalData
