import { getFakeDataValue } from '@helpers/chartBarFunctions'
import { IMeasurement, IAggregationMeasurements } from '@interfaces/measurements'

export const mappedMeasurementsData = (aggregationData: IAggregationMeasurements) => {
  aggregationData.measurements.forEach((measurement: IMeasurement) => {
    if (measurement.amountHighRate && measurement.amountLowRate) {
      const amountLowRate = parseFloat(measurement.amountLowRate)
      const amountHighRate = parseFloat(measurement.amountHighRate)
      measurement.sumOfRates = String(amountLowRate + amountHighRate)
    } else {
      measurement.noDataValue = 1
      measurement.sumOfRates = '0.00'
    }
  })
  return aggregationData
}

export const noDataBarValue = (aggregationData: IAggregationMeasurements) => {
  const mapSumOfRates = aggregationData.measurements
    .map((sumOfRates) => Number(sumOfRates.sumOfRates))
    .filter((rate) => !Number.isNaN(rate))

  const maximumValue = Math.max(...mapSumOfRates) * 0.75

  aggregationData.measurements.forEach((measurement: IMeasurement) => {
    if (measurement.noDataValue) {
      measurement.noDataValue = getFakeDataValue(maximumValue)
    }
  })
  return aggregationData
}
