import DtpTextField from '@components/DtpTextField/DtpTextField'
import { Box, Stack, Button, Typography } from '@mui/material'
import { useMask } from '@react-input/mask'
import { accessCodeSchema } from '@src/validations/authorizationValidationSchema'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { FormSteps, IStepperProps } from './DtpAuthCard'
import TooltipLabel from './TooltipLabel'

const AccessCodeForm = ({ setStep }: IStepperProps) => {
  const { t } = useTranslation()

  const accessCodeInput = useMask({ mask: '___ ___', replacement: '_' })

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    validationSchema: accessCodeSchema,
    initialValues: {
      accessCode: '',
    },
    onSubmit: () => {},
  })

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={4}>
        <Typography variant="heading3">{t('authorization.enter_access_code')}</Typography>
        <Typography variant="bodyRegular">{t('authorization.enter_code_explanation')}</Typography>
      </Box>
      <DtpTextField
        value={values.accessCode}
        onChange={handleChange}
        name="accessCode"
        inputRef={accessCodeInput}
        label={
          <TooltipLabel label={t('authorization.access_code')} tooltipLabel={t('authorization.tooltip_access_code')} />
        }
        inputMode="tel"
        helperText={touched.accessCode ? errors.accessCode : ''}
        error={touched.accessCode && Boolean(errors.accessCode)}
        placeholder="xxx xxx xxx"
        fullWidth
      />

      <Box mt={3}>
        <Button variant="text" size="large" sx={{ p: '0 !important' }} onClick={() => setStep(FormSteps.NEW_CODE_STEP)}>
          {t('authorization.request_new_code')}
        </Button>
        <Stack direction="row" alignItems="center" mt={4} gap={1}>
          <Button variant="contained" size="large" type="submit">
            {t('authorization.complete')}
          </Button>
          <Button variant="outlined" size="large" onClick={() => setStep(FormSteps.ACCOUNT_NUMBER_STEP)}>
            {t('back')}
          </Button>
        </Stack>
      </Box>
    </form>
  )
}

export default AccessCodeForm
