import { ArrowDiagonalIcon } from '@assets/icons'
import theme from '@assets/themes/theme'
import { Button, Chip, Link, styled, Typography } from '@mui/material'
import InvoiceSettingsWrapper from '@src/ui/wrappers/InvoiceSettingsWrapper/InvoiceSettingsWrapper'
import { Trans, useTranslation } from 'react-i18next'

const EBill = () => {
  const { t } = useTranslation()
  const StyledChip = styled(Chip)(() => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontFamily: 'GothamMedium',
    fontSize: '16px',
    marginRight: 3,
    width: 26,
    height: 26,
    '& .MuiChip-label': {
      lineHeight: '17px',
    },
  }))
  return (
    <InvoiceSettingsWrapper
      buttonTitle={t('back')}
      link={t('navigation_url.invoices_settings')}
      title={t('e_bill.e_bill')}
      sx={{ gap: 0 }}
    >
      <Typography variant="bodyRegular" maxWidth={680}>
        {t('e_bill.ebill_explanation')}
      </Typography>

      <Typography variant="bodyRegular" fontFamily="GothamMedium">
        {t('e_bill.steps_for_activation')}
      </Typography>

      <Typography variant="bodyRegular" fontFamily="GothamMedium">
        <StyledChip size="small" label="1" /> {t('e_bill.title_login_online_banking')}
      </Typography>
      <Typography variant="bodyRegular" maxWidth={680} pl={4}>
        {t('e_bill.explanation_login_online_banking')}
      </Typography>

      <Typography variant="bodyRegular" fontFamily="GothamMedium">
        <StyledChip size="small" label="2" /> {t('e_bill.title_active_ebill')}
      </Typography>
      <Typography variant="bodyRegular" maxWidth={680} pl={4}>
        <Trans i18nKey="e_bill.explanation_active_ebill" />
      </Typography>
      <Typography variant="bodyRegular" fontFamily="GothamMedium">
        <StyledChip size="small" label="3" /> {t('register')}
      </Typography>
      <Typography variant="bodyRegular" maxWidth={680} pl={4}>
        <Trans i18nKey="e_bill.explanation_register_ebill" />
      </Typography>
      <Button
        variant="text"
        LinkComponent={Link}
        href="https://www.ckw.ch/landingpages/ebill"
        target="_blank"
        sx={{ maxWidth: 'fit-content' }}
        endIcon={<ArrowDiagonalIcon />}
      >
        {t('e_bill.to_ebill_info')}
      </Button>
    </InvoiceSettingsWrapper>
  )
}

export default EBill
