export enum InvoiceDeliveryMethod {
  EBILL = 'EBILL',
  EMAIL = 'EMAIL',
  POST = 'POST',
}
export enum InvoicePaymentMethod {
  EBILL = 'EBILL',
  MANUAL = 'MANUELL',
  DIRECT_DEBIT = 'LSV',
}
