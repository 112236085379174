import { UserRoles } from '@enums/userRoles'
import { useAuth } from '@hooks/useAuth/useAuth'

const useRoleAccess = () => {
  const { hasRole, isAuthenticated, user } = useAuth()
  const isBetaTester = hasRole(UserRoles.BETA_TESTER)
  const isEndUser = hasRole(UserRoles.END_USER)
  const isProduction = process.env.REACT_APP_NODE_ENV === 'production'

  const fullAccess = isEndUser && isBetaTester

  const disabledStyles = { pointerEvents: fullAccess ? 'auto' : 'none', opacity: fullAccess ? 1 : 0.5 }

  return { isBetaTester, isEndUser, user, isAuthenticated, disabledStyles, isProduction }
}

export default useRoleAccess
