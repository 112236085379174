import { Stack, Typography } from '@mui/material'

const HighlightedContent = ({ icon, label }: { icon: React.ReactNode; label: string }) => {
  return (
    <Stack bgcolor="#FBFCE2" flexDirection="row" alignItems="center" borderRadius={3} mb={2}>
      {icon}
      <Typography variant="bodySmall" fontFamily="GothamBook" pr={2}>
        {label}
      </Typography>
    </Stack>
  )
}

export default HighlightedContent
