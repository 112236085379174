import { Stack, Skeleton, Box } from '@mui/material'

import DtpInvoiceCardSkeleton from './DtpInvoiceCardSkeleton'

const DtpInvoiceCardGroupSkeleton = () => {
  return (
    <>
      <Box maxWidth={200}>
        <Skeleton sx={{ mb: { xs: 3, md: 5 } }} height={45} />
      </Box>
      <Stack mb={2} flexWrap="wrap" direction="row" columnGap={3}>
        <DtpInvoiceCardSkeleton />
        <DtpInvoiceCardSkeleton />
      </Stack>
    </>
  )
}

export default DtpInvoiceCardGroupSkeleton
