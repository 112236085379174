import { IComparisonResponse, IMeasurement } from '@interfaces/measurements'

export const comparisonDataPrepack = (comparationData: IComparisonResponse): (IMeasurement | undefined)[] => {
  if (!comparationData.period1.length && !comparationData.period2.length) {
    return []
  }
  if (comparationData.period1.length === 0) {
    return [undefined, ...comparationData.period2]
  }
  if (comparationData.period2.length === 0) {
    return [...comparationData.period1, undefined]
  }
  return [...comparationData.period1, ...comparationData.period2]
}
