import { DownloadIcon } from '@assets/icons'
import { Button, Link, Typography } from '@mui/material'
import InvoiceSettingsWrapper from '@src/ui/wrappers/InvoiceSettingsWrapper/InvoiceSettingsWrapper'
import { Trans, useTranslation } from 'react-i18next'

const DirectDebit = () => {
  const { t } = useTranslation()
  return (
    <InvoiceSettingsWrapper
      buttonTitle={t('back')}
      link={t('navigation_url.invoices_settings')}
      title={t('invoices_settings.direct_debit')}
    >
      <Typography variant="bodyRegular" maxWidth={680}>
        {t('invoices_settings.direct_debit_description_1')}
      </Typography>

      <Typography variant="bodyRegular" maxWidth={680}>
        <Trans
          i18nKey="invoices_settings.direct_debit_description_2"
          components={{
            Email: (
              <Link
                underline="hover"
                href={`mailto:debitoren@ckw.ch?subject=${t('invoices_settings.direct_debit')}`}
              ></Link>
            ),
          }}
        />
      </Typography>

      <Button
        variant="text"
        LinkComponent={Link}
        href="https://www.ckw.ch/_Resources/Persistent/b/5/8/1/b581eb5de651d0a9888887e55e2ffdeba929bae9/LSV Belastungsermächtigung.pdf"
        target="_blank"
        sx={{ maxWidth: 'fit-content', p: 0.5, mt: 2 }}
        startIcon={<DownloadIcon />}
      >
        {t('invoices_settings.direct_debit_registration_form')}
      </Button>
    </InvoiceSettingsWrapper>
  )
}

export default DirectDebit
