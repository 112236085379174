import { menuItemSharedStyles } from '@components/AccountsAccordion/AccountsAccordion'
import { useAuth } from '@hooks/useAuth/useAuth'
import { Typography, useTheme, List, styled, ListItem, ListItemText, SxProps } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

interface IRenderMenuItemProps {
  title: string
  toggleDrawer: () => void
  routes: { name: string; path: string }[]
  itemStyles: SxProps
  showAdditionalLink?: boolean
}

const MobileNavItem = styled(NavLink)(({ theme }) => ({
  ...menuItemSharedStyles,
  '&.active': {
    color: theme.palette.primary.main,
    '&::before': {
      background: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
  },
  '&:hover': {
    '&::before': {
      background: theme.palette.text.primary,
    },
    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
    },
  },
}))

const RenderMenuItem = ({ toggleDrawer, title, routes, itemStyles, showAdditionalLink }: IRenderMenuItemProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { getKeycloakAccountPage } = useAuth()
  const handleRedirectToKCPage = (e: React.SyntheticEvent) => {
    e.preventDefault()
    getKeycloakAccountPage()
  }
  return (
    <>
      <Typography
        variant="bodySmall"
        fontFamily={'GothamBook'}
        fontWeight={400}
        sx={{ mx: { xs: 4, md: 1.5 }, my: 0, mt: { xs: 0.75, md: 0 } }}
        color={theme.palette.text.secondary}
      >
        {title}
      </Typography>
      <Show when={!!showAdditionalLink}>
        <MobileNavItem to={'/login-security'} onClick={handleRedirectToKCPage} sx={{ py: 0, mt: 1 }}>
          <ListItemText sx={{ m: 0 }}>{t('login_security')}</ListItemText>
        </MobileNavItem>
      </Show>
      <List sx={{ py: 0, my: 0 }}>
        {routes.map(({ path, name }) => {
          if (!name) return
          return (
            <ListItem key={name} sx={{ m: 0, my: 1, p: 0, ...itemStyles }}>
              <MobileNavItem to={path} onClick={toggleDrawer} sx={{ py: 0 }}>
                <ListItemText sx={{ m: 0 }}>{t(name)}</ListItemText>
              </MobileNavItem>
            </ListItem>
          )
        })}
      </List>
    </>
  )
}

export default RenderMenuItem
