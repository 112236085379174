import DtpInvoiceCardSkeleton from '@components/Skeletons/DtpInvoiceCardSkeleton'
import { Container, Box, Stack, Skeleton } from '@mui/material'

import DtpInvoiceDetailsSkeleton from './DtpInvoiceDetailsSkeleton'

const InvoiceDetailsPageSkeleton = () => {
  return (
    <Container>
      <Box mb={6}>
        <Skeleton width={144} height={40} sx={{ mt: 6, mb: 1.2 }} />
        <Skeleton width="40%" height={40} />
      </Box>
      <Stack direction={{ xs: 'column-reverse', md: 'row' }} justifyContent="space-between" columnGap={4}>
        <Box maxWidth={{ xs: '100%', md: 687 }} width="100%">
          <DtpInvoiceDetailsSkeleton />
        </Box>
        <Box>
          <DtpInvoiceCardSkeleton />
          <Skeleton height={23} width="30%" />
          <Skeleton height={23} width="50%" />
          <Skeleton height={48} />
          <Skeleton height={48} />
        </Box>
      </Stack>
    </Container>
  )
}

export default InvoiceDetailsPageSkeleton
