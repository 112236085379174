import CancelIcon from '@mui/icons-material/Cancel'
import SearchIcon from '@mui/icons-material/Search'
import { Input, useTheme, IconButton, ListSubheader } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export interface IDtpSearchBarProps {
  showClearIcon: boolean
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClearSearch?: () => void
}

const DtpSearchBar = React.forwardRef<HTMLInputElement, IDtpSearchBarProps>((props, ref) => {
  const { showClearIcon, value, onChange, onClearSearch } = props

  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <ListSubheader>
      <Input
        inputRef={ref}
        placeholder={t('contracts.searchbar_placeholder')}
        startAdornment={<SearchIcon />}
        onKeyDown={(e) => e.stopPropagation()}
        endAdornment={
          showClearIcon && (
            <IconButton sx={{ p: 0 }} onClick={onClearSearch}>
              <CancelIcon />
            </IconButton>
          )
        }
        value={value}
        onChange={onChange}
        disableUnderline
        sx={{
          border: '1px solid #8D968D',
          padding: theme.spacing(1.25, 3),
          borderRadius: theme.borderRadius.xl,
          my: 1.5,
          mb: 2,
          ':hover': {
            border: '1px solid #8D968D',
          },
          '&.Mui-focused': {
            background: 'transparent',
            border: '1px solid #8D968D',
            boxShadow: '0px 0px 0px 2px #C8E6A4',
          },
        }}
        slotProps={{ input: { sx: { p: 0 } } }}
      />
    </ListSubheader>
  )
})

DtpSearchBar.displayName = 'DtpSearchBar'

export default DtpSearchBar
