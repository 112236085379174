import DtpError from '@components/DtpError/DtpError'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <DtpError
      suptitle={t('error_404')}
      title={t('description_404_dead_end')}
      subtitle={t('description_404_page_lost')}
      slot={
        <NavLink to={'/'}>
          <Button variant="outlined">{t('back_to_home_page')}</Button>
        </NavLink>
      }
    />
  )
}

export default NotFound
