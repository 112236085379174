import { Skeleton, Container, Stack } from '@mui/material'

import DtpInvoiceCardSkeleton from './DtpInvoiceCardSkeleton'
import DtpPaidInvoicesAccordionSkeleton from './DtpPaidInvoicesAccordionSkeleton'

const InvoicePageSkeleton = () => {
  return (
    <Container>
      <Skeleton sx={{ mt: { xs: 1.5, sm: 5, md: 10 }, mb: { xs: 1, sm: 3, md: 6 } }} width={200} height={40} />

      <Skeleton sx={{ mb: { xs: 3, sm: 5 } }} width={100} height={40} />

      <Stack mb={2} flexWrap="wrap" direction="row" columnGap={3}>
        <DtpInvoiceCardSkeleton />
        <DtpInvoiceCardSkeleton />
      </Stack>

      <Stack width="fit-content" gap={0} sx={{ mb: { xs: 2, sm: 5 } }}>
        <Skeleton width={250} height={40} />
        <Skeleton width={250} height={40} />
        <Skeleton width={250} height={40} />
      </Stack>
      <DtpPaidInvoicesAccordionSkeleton />
    </Container>
  )
}

export default InvoicePageSkeleton
