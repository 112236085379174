import DtpToast from '@components/DtpToast/DtpToast'
import { toast } from 'react-toastify'

interface INotificationOptions {
  severity?: 'error' | 'info' | 'warning' | 'success'
  autoHideDuration?: number
}

const useNotification = () => {
  return (
    severity: 'error' | 'info' | 'warning' | 'success',
    title?: string,
    message?: string,
    options?: INotificationOptions
  ) => {
    toast[severity](
      <DtpToast
        title={title}
        message={message}
        severity={severity}
        autoHideDuration={options?.autoHideDuration ?? 5000}
      />
    )
  }
}

export default useNotification
