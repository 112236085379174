import theme from '@assets/themes/theme'
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input'

const styledMuiTelInput = {
  '.MuiOutlinedInput-root': {
    border: '1px solid #8D968D !important',
  },
  '& .MuiInputBase-root': {
    marginTop: '5px',
    fontSize: theme.typography.pxToRem(16),
    padding: '3.5px 4px',
    border: '1px solid #8D968D',
    borderRadius: '8px',
    outline: '2px solid transparent',
    '&:hover, &.Mui-focused': {
      border: '2px solid #8D968D',
      outline: `2px solid ${theme.palette.primary.lighter}`,
    },
    '&.Mui-focused': {
      background: '#F1F9E8 !important',
    },
  },
  '& .MuiFormHelperText-root': {
    color: 'red',
  },
  '.MuiFormLabel-root': {
    fontSize: theme.typography.pxToRem(16),
    transform: 'translate(0, -4px)',
  },
}

type DtpPhoneInputProps = MuiTelInputProps & {
  label?: string
  error?: boolean
  helperText?: React.ReactNode
}

const DtpPhoneInput = (props: DtpPhoneInputProps) => {
  const { label, error, helperText, ...rest } = props
  return <MuiTelInput label={label} error={error} helperText={helperText} {...rest} sx={styledMuiTelInput} />
}

export default DtpPhoneInput
