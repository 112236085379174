import { useAccountStore } from '@src/stores/accounts/useAccountStore'
import { useLocationStore } from '@src/stores/locations/useLocationStore'
import Keycloak, { KeycloakConfig, KeycloakInitOptions, KeycloakTokenParsed } from 'keycloak-js'
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'

import { SuspenseLoading } from '../ui/components/SuspenseLoading/SuspenseLoading'

type AuthContextValues = {
  isAuthenticated: boolean
  logout: () => void
  hasRole: (role: string) => boolean
  keycloakError: boolean
  getKeycloakAccountPage: () => void
  user?: KeycloakTokenParsed
}
const keycloakURL = process.env.REACT_APP_KEYCLOAK_URL
const keycloakRealm = String(process.env.REACT_APP_KEYCLOAK_REALM)
const keycloakClientId = String(process.env.REACT_APP_KEYCLOAK_CLIENT_ID)

const keycloakConfig: KeycloakConfig = {
  url: keycloakURL,
  realm: keycloakRealm,
  clientId: keycloakClientId,
}

const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'login-required',
  flow: window.location.origin.includes('-dtp-') ? 'implicit' : 'standard',
  checkLoginIframe: false,
}

export const keycloak = new Keycloak(keycloakConfig)

const defaultAuthContextValues: AuthContextValues = {
  isAuthenticated: false,
  keycloakError: false,
  logout: () => {},
  hasRole: () => false,
  getKeycloakAccountPage: () => {},
}

const resetActiveAccount = useAccountStore.getState().resetActiveAccount
const resetLocations = useLocationStore.getState().resetLocations
export const AuthContext = createContext<AuthContextValues>(defaultAuthContextValues)

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [keycloakError, setKeycloakError] = useState<boolean>(false)
  const [user, setUser] = useState<KeycloakTokenParsed | undefined>(undefined)
  const initializeKeycloak = useCallback(async () => {
    resetActiveAccount()
    resetLocations()
    setKeycloakError(false)
    try {
      const isAuthenticatedResponse = await keycloak.init(keycloakInitOptions)
      if (!isAuthenticatedResponse) {
        keycloak.login()
      }
      setIsAuthenticated(isAuthenticatedResponse)

      localStorage.setItem('ckw-token', JSON.stringify(keycloak.token))
      setUser(keycloak.idTokenParsed)
    } catch (error) {
      setIsAuthenticated(false)
      setKeycloakError(true)
      localStorage.removeItem('ckw-token')
    }
  }, [])

  useEffect(() => {
    initializeKeycloak()
  }, [initializeKeycloak])

  const logout = useCallback(async () => {
    try {
      resetActiveAccount()
      resetLocations()
      await keycloak.logout()
      localStorage.removeItem('ckw-token')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('keycloak-logout error:', error)
    }
  }, [])

  keycloak.onTokenExpired = async () => {
    try {
      const isRefreshed = await keycloak.updateToken(5)
      if (isRefreshed) {
        localStorage.setItem('ckw-token', JSON.stringify(keycloak.token))
      }
    } catch (error) {
      keycloak.logout()
      throw error
    }
  }

  const hasRole = useCallback((role: string) => keycloak.hasRealmRole(role), [])
  const getKeycloakAccountPage = useCallback(() => keycloak?.accountManagement(), [])

  const contextValue = useMemo(() => {
    return { isAuthenticated, keycloakError, logout, hasRole, user, getKeycloakAccountPage }
  }, [isAuthenticated, keycloakError, logout, hasRole, user, getKeycloakAccountPage])

  return (
    <React.StrictMode>
      <AuthContext.Provider value={contextValue}>
        {isAuthenticated || keycloakError ? children : <SuspenseLoading />}
      </AuthContext.Provider>
    </React.StrictMode>
  )
}

export default AuthContextProvider
