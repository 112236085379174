import { getDownloadAggregationMesurements } from '@api/queries'
import { DownloadIcon } from '@assets/icons'
import DtpErrorModal from '@components/DtpErrorModal/DtpErrorModal'
import DtpModal from '@components/DtpModal/DtpModal'
import { AggregationEnum } from '@enums/aggregationTypes'
import { getAggregationsStartEndDate } from '@helpers/dateFunctions'
import { createFileName, downloadFile } from '@helpers/downloadCSVFunctions'
import { IAddOn } from '@hooks/useLocations/useLocationsData'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
} from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useQuery } from '@tanstack/react-query'
import { OpUnitType } from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const modalStyles = {
  '.MuiPaper-root': {
    padding: '24px 32px',
    maxWidth: '360px',
    width: '100%',
    borderRadius: '24px',
  },
}
export interface IDtpDownloadCSVProps {
  customAggregation: boolean
  aggregation: AggregationEnum
  chartType: OpUnitType
  date: string
  startModalDate: string
  endModalDate: string
  addOn: IAddOn
  timestampStartOfPeriod?: string
  isDisabled?: boolean
  isLoading?: boolean
}
const DtpDownloadCSV = ({
  customAggregation,
  aggregation,
  chartType,
  date,
  startModalDate,
  endModalDate,
  addOn,
  timestampStartOfPeriod,
  isDisabled,
  isLoading,
}: IDtpDownloadCSVProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)

  const initialValue = t('power_consumption_page.current_selection')
  const [fileName, setFileName] = useState<string>(initialValue)
  const dateFormat = chartType === 'day' ? 'DD. MMM YYYY' : chartType === 'month' ? 'MMMM YYYY' : 'YYYY'
  const { gpnr, meterId, locationId, contractId } = addOn
  const handleCloseDownload = () => {
    setOpen(false)
    setFileName(initialValue)
  }
  const handleOpenDownload = () => setOpen(true)
  const { startDate, endDate } = getAggregationsStartEndDate(
    date,
    chartType,
    customAggregation,
    startModalDate,
    endModalDate
  )
  const { refetch, isFetching: isLoadingDownloadAggregation } = useQuery({
    queryKey: ['downloadCSVData'],
    queryFn: () =>
      getDownloadAggregationMesurements({
        gpnr,
        meterId,
        aggregation: fileName === initialValue ? aggregation : AggregationEnum.FIFTEEN_MINUTES,
        startDate,
        endDate,
        locationId,
        contractId,
      }),
    enabled: false,
    onSuccess: (data: File) => {
      downloadFile({
        data: data,
        fileName: createFileName(
          customAggregation,
          fileName,
          startModalDate,
          endModalDate,
          timestampStartOfPeriod,
          dateFormat
        ),
        fileType: 'text/csv',
      })
      setOpen(false)
      setErrorModalOpen(false)
    },
    onError: () => {
      setErrorModalOpen(true)
      setOpen(false)
    },
  })
  const handleDownloadValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value)
  }

  if (isLoading)
    return (
      <Box sx={{ padding: { xs: '14px 16px', md: '16px 20px' } }}>
        <Skeleton animation="wave" variant="rounded" width={150} height={28} />
      </Box>
    )
  return (
    <>
      <Button
        sx={{ Self: 'end' }}
        variant="text"
        startIcon={<DownloadIcon />}
        onClick={handleOpenDownload}
        disabled={isDisabled}
      >
        {t('power_consumption_page.download')}
      </Button>
      <DtpModal
        open={open}
        sx={modalStyles}
        title={t('power_consumption_page.download')}
        onClose={handleCloseDownload}
        closeOutside
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="download-as-csv"
            value={fileName}
            name="download-as-csv"
            sx={{ mt: 2 }}
            onChange={handleDownloadValueChange}
          >
            <FormControlLabel
              value={t('power_consumption_page.current_selection').toString()}
              control={<Radio />}
              label={t('power_consumption_page.current_selection').toString()}
            />
            <FormControlLabel
              value={t('power_consumption_page.values_15_min').toString()}
              control={<Radio />}
              label={t('power_consumption_page.values_15_min').toString()}
            />
          </RadioGroup>
        </FormControl>
        <Button
          variant="contained"
          type="submit"
          sx={{ mt: 2 }}
          onClick={() => refetch()}
          disabled={isLoadingDownloadAggregation}
        >
          <Show when={isLoadingDownloadAggregation}>
            <CircularProgress size={30} color="inherit" />
          </Show>
          <Show when={!isLoadingDownloadAggregation}>{t('power_consumption_page.downlaod_as_csv')}</Show>
        </Button>
      </DtpModal>
      <DtpErrorModal
        errorTitle={t('http_errors.general_error_title')}
        errorDescription={t('http_errors.general_error_description_retry')}
        open={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        isLoading={isLoadingDownloadAggregation}
        onConfirm={() => refetch()}
      />
    </>
  )
}
export default DtpDownloadCSV
