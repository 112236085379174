import { ErrorExclamationIcon } from '@assets/icons'
import DtpDateField from '@components/DtpDateField/DtpDateField'
import DtpModal from '@components/DtpModal/DtpModal'
import { getMinMaxDateFromContract } from '@helpers/dateFunctions'
import { Stack, Button, FormHelperText, Divider, styled, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

interface IFormValues {
  startDate: string
  endDate: string
}
export interface IChartControlCustomPeriodProps extends IFormValues {
  supplyStart?: string
  supplyEnd?: string
  lastAvailableDay?: string
  modalOpen: boolean
  onConfirmModal: ({ startDate, endDate }: IFormValues) => void
  onCloseModal: () => void
}

const CustomDivider = styled(Divider)({
  width: 8,
  height: 2,
  backgroundColor: '#232623',
  alignSelf: 'flex-end',
  margin: '0 16px 20px 16px',
})

const DateRangeModal = ({
  startDate,
  endDate,
  supplyStart,
  supplyEnd,
  lastAvailableDay,
  modalOpen,
  onCloseModal,
  onConfirmModal,
}: IChartControlCustomPeriodProps) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const formatDate = 'YYYY-MM-DD'

  const isWithinContractPeriod = dayjs(supplyEnd).isSameOrBefore(dayjs(lastAvailableDay).format(formatDate))

  const validationMaxDate = isWithinContractPeriod
    ? dayjs(supplyEnd).format(formatDate)
    : dayjs().subtract(1, 'day').format(formatDate)

  const validationMessage = isWithinContractPeriod
    ? 'power_consumption_page.error_message_no_within_contract_period'
    : 'power_consumption_page.error_date_must_be_yesterday_or_before'

  const { minDate } = getMinMaxDateFromContract(dayjs(supplyStart), dayjs())

  const validationSchema = yup.object().shape({
    startDate: yup
      .date()
      .typeError(t('power_consumption_page.error_invalid_input'))
      .min(dayjs().subtract(10, 'year').toDate(), t('power_consumption_page.error_date_too_long_ago'))
      .min(minDate.toDate(), t('power_consumption_page.error_message_no_within_contract_period'))
      .max(validationMaxDate, t(validationMessage)),
    endDate: yup
      .date()
      .typeError(t('power_consumption_page.error_invalid_input'))
      .min(yup.ref('startDate'), t('power_consumption_page.error_message_invalid_time_range'))
      .max(validationMaxDate, t(validationMessage)),
  })

  const [date, setDate] = useState<{ startDate: Dayjs; endDate: Dayjs }>({
    startDate: dayjs(startDate),
    endDate: dayjs(endDate),
  })

  const [errors, setErrors] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  })

  const handleOnChange = (value: Dayjs | null, field: 'startDate' | 'endDate') => {
    if (!value) return

    setDate((prevDate) => ({
      ...prevDate,
      [field]: value,
    }))
  }

  const handleBlur = async (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    field: 'startDate' | 'endDate'
  ) => {
    const value = event.target.value
    try {
      await validationSchema.validateAt(field, { [field]: value, ...date })
      setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }))
    } catch (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: (error as Error).message }))
    }
  }

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(date, { abortEarly: false })
      setErrors({ startDate: '', endDate: '' })
      onConfirmModal({
        startDate: date.startDate.format('YYYY-MM-DD'),
        endDate: date.endDate.format('YYYY-MM-DD'),
      })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const newErrors: { startDate: string; endDate: string } = {
          startDate: '',
          endDate: '',
        }
        error.inner.forEach((e) => {
          newErrors[e.path as 'startDate' | 'endDate'] = e.message
        })
        setErrors(newErrors)
      }
    }
  }

  return (
    <DtpModal
      open={modalOpen}
      onClose={onCloseModal}
      title={t('power_consumption_page.energy_consumption_custom_date')}
    >
      <Stack direction={isMobile ? 'column' : 'row'} mt={3} mb={isMobile ? 3 : 0} spacing={isMobile ? 1 : 0}>
        <DtpDateField
          fullWidth
          label={t('power_consumption_page.energy_consumption_start_date')}
          value={startDate ? date.startDate : null}
          onChange={(value) => handleOnChange(value, 'startDate')}
          onBlur={(event) => handleBlur(event, 'startDate')}
          error={!!errors.startDate}
        />
        <Show when={isMobile}>
          <ErrorText error={errors.startDate} />
        </Show>
        <Show when={!isMobile}>
          <CustomDivider />
        </Show>
        <DtpDateField
          fullWidth
          label={t('power_consumption_page.energy_consumption_end_date')}
          value={endDate ? date.endDate : null}
          onChange={(value) => handleOnChange(value, 'endDate')}
          onBlur={(event) => handleBlur(event, 'endDate')}
          error={!!errors.endDate}
          disabled={!!errors.startDate}
        />
        <Show when={isMobile}>
          <ErrorText error={errors.endDate} />
        </Show>
      </Stack>
      <Show when={!isMobile}>
        <Stack direction="row" justifyContent="space-between">
          <ErrorText error={errors.startDate} maxWidth={169} />
          <ErrorText error={errors.endDate} maxWidth={169} moveRight />
        </Stack>
      </Show>
      <Button fullWidth onClick={handleSubmit} sx={{ marginTop: !!errors.startDate || !!errors.endDate ? 0 : 4 }}>
        {t('power_consumption_page.confirm_selection')}
      </Button>
    </DtpModal>
  )
}

export default DateRangeModal

const ErrorText = ({ error, maxWidth, moveRight }: { error: string; maxWidth?: number; moveRight?: boolean }) => {
  if (!error) return <></>
  return (
    <FormHelperText
      sx={{ display: 'block', maxWidth: maxWidth ? maxWidth : undefined, marginLeft: moveRight ? 'auto' : 0 }}
      error
    >
      <ErrorExclamationIcon /> {error}
    </FormHelperText>
  )
}
