import { FormControl, RadioGroup, RadioGroupProps } from '@mui/material'
import React from 'react'

interface IDtpCardSelectProps extends RadioGroupProps {
  value?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  children: React.ReactNode
}

export default function DtpCardSelect({ value, onChange, children, ...rest }: Readonly<IDtpCardSelectProps>) {
  return (
    <FormControl sx={{ display: 'flex', maxWidth: 550, width: '100%' }}>
      <RadioGroup
        aria-labelledby="select-payment-method"
        name="select-payment-method"
        value={value}
        onChange={onChange}
        {...rest}
      >
        {children}
      </RadioGroup>
    </FormControl>
  )
}
