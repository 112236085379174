import { getComparisonData } from '@api/queries'
import { CompareIcon } from '@assets/icons'
import ComparationChart from '@components/ComparationChart/ComparationChart'
import { DtpDatePicker } from '@components/DtpDatePicker/DtpDatePicker'
import ProsumerComparationChart from '@components/ProsumerComparationChart/ProsumerComparationChart'
import CompareCardSkeleton from '@components/Skeletons/CompareCardSkeleton'
import { comparisonDataPrepack } from '@helpers/comparisonFunctions'
import {
  getAggregationsStartEndDate,
  getDefaultComparationFromContract,
  getDefaultMinMaxDateFromContract,
} from '@helpers/dateFunctions'
import { IComparisonApiResponse, IComparisonResponse, IMeasurement } from '@interfaces/measurements'
import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

export interface ICompareCardProps {
  datePickerValue: string
  title: string
  gpnr?: string
  aggregationType: 'month' | 'year'
  locationId?: string
  meterId?: string
  contractId?: string
  subtitle?: string
  contractStartDate?: string
  contractEndDate?: string
  unit?: 'kWh' | 'CHF'
  isLoading?: boolean
  setActiveTab?: (activeTab: number) => void
  setSelectedFromConsumptionDetail?: (selectedFromConsumptionDetail: string) => void
  deliveryType?: string
  isFeedIn?: boolean
}

const DtpCompareCard = ({
  datePickerValue,
  title,
  subtitle,
  unit = 'kWh',
  gpnr,
  locationId,
  meterId,
  contractId,
  contractStartDate,
  contractEndDate,
  aggregationType,
  isLoading = false,
  setActiveTab,
  setSelectedFromConsumptionDetail,
  isFeedIn,
  deliveryType,
}: ICompareCardProps) => {
  const [period1, setPeriod1] = useState<string>('')
  const [period2, setPeriod2] = useState<string>('')
  const [comparationData, setComparationData] = useState<(IMeasurement | undefined)[]>([])
  const dateFormat = 'YYYY-MM-DD'
  const { minDate, maxDate } = getDefaultMinMaxDateFromContract(
    aggregationType,
    datePickerValue,
    contractEndDate ?? '',
    contractStartDate ?? '',
    dateFormat
  )

  const consumptionDetailClick = (value: string, tab: number) => {
    setSelectedFromConsumptionDetail && setSelectedFromConsumptionDetail(value)
    setActiveTab && setActiveTab(tab)
  }
  const tooltipTabIndexYear = aggregationType === 'year' ? 2 : 0
  const tooltipTabIndex = aggregationType === 'month' ? 1 : tooltipTabIndexYear

  const mapComparisonData = ({ body }: { body: IComparisonResponse }) => {
    const modifiedData = comparisonDataPrepack(body)
    modifiedData.forEach((measurement: IMeasurement | undefined) => {
      if (measurement?.value) {
        if (isFeedIn && deliveryType === 'feedIn') {
          measurement.feedIn = String(measurement.value)
        } else if (isFeedIn && deliveryType === 'consumptionSwitch') {
          measurement.balance = String(measurement.value)
        } else if (isFeedIn && deliveryType === undefined) {
          measurement.balance = String(measurement.value)
        } else {
          measurement.consumption = String(measurement.value)
        }
      }
    })
    setComparationData(modifiedData)
  }

  const params = {
    gpnr,
    locationId: locationId ? locationId : undefined,
    meterId: meterId ? meterId : undefined,
    contractId: contractId ? contractId : undefined,
    aggregation: aggregationType.toUpperCase(),
    period1: { ...getAggregationsStartEndDate(period1, aggregationType) },
    period2: { ...getAggregationsStartEndDate(period2, aggregationType) },
  }

  const { isLoading: isLoadingComparation } = useQuery<IComparisonApiResponse>({
    queryKey: ['comparisonData', { ...params }],
    queryFn: () => getComparisonData(params),
    enabled:
      !!gpnr &&
      (!!locationId || (!!meterId && !!contractId)) &&
      !!contractStartDate &&
      !!contractEndDate &&
      !!datePickerValue &&
      !isLoading &&
      !!period1 &&
      !!period2,
    onSuccess: mapComparisonData,
    cacheTime: 0,
  })

  useEffect(() => {
    if (contractEndDate && contractStartDate && maxDate) {
      const { firstPeriod, secondPeriod } = getDefaultComparationFromContract(
        contractStartDate,
        contractEndDate,
        maxDate
      )
      setPeriod1(firstPeriod)
      setPeriod2(secondPeriod)
    }
  }, [contractEndDate, contractStartDate, maxDate, meterId])

  if (isLoading || isLoadingComparation) return <CompareCardSkeleton chartType={aggregationType} />

  return (
    <Card
      sx={{
        boxShadow: 0,
        border: '1px solid #D5D8D5',
        borderRadius: '24px',

        px: { xs: 1, md: 6 },
        py: { xs: 2, md: 4 },
        overflow: 'visible',
      }}
    >
      <CardHeader
        sx={{ p: { xs: 1, md: 0 } }}
        title={
          <Typography sx={{ m: '0 0 8px 0' }} variant="heading4">
            {title}
          </Typography>
        }
        subheader={
          <Typography sx={{ m: '0 0 8px 0' }} variant="bodyRegularEmphasized">
            {subtitle}
          </Typography>
        }
      />
      <CardContent sx={{ paddingBottom: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ gap: { sm: 0, md: 1 }, '& div, & button': { width: '100%' } }}
          mb={3}
        >
          <DtpDatePicker
            value={period1}
            onChange={setPeriod1}
            viewType={aggregationType}
            maxDate={maxDate}
            minDate={minDate}
            variant="outlined"
          />

          <CompareIcon fontSize="large" />

          <DtpDatePicker
            value={period2}
            onChange={setPeriod2}
            viewType={aggregationType}
            maxDate={maxDate}
            minDate={minDate}
            variant="outlined"
          />
        </Stack>
        <Show when={!isFeedIn}>
          <ComparationChart
            data={comparationData}
            isLoading={isLoadingComparation}
            unit={unit}
            aggregationType={aggregationType}
            handleOnDateChange={(value: string) => consumptionDetailClick(value, tooltipTabIndex)}
          />
        </Show>
        <Show when={!!isFeedIn}>
          <ProsumerComparationChart
            data={comparationData}
            isLoading={isLoadingComparation}
            unit={unit}
            aggregationType={aggregationType}
            handleOnDateChange={(value: string) => consumptionDetailClick(value, tooltipTabIndex)}
            deliveryType={deliveryType}
          />
        </Show>
      </CardContent>
    </Card>
  )
}

export default DtpCompareCard
