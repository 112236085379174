import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, Menu, MenuItem, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IDtpMenuProps {
  onSetMainAccount?: () => void
  onChangeAccount?: () => void
  onDeleteAccount?: () => void
}

const DtpMenu = ({ onSetMainAccount, onChangeAccount, onDeleteAccount }: IDtpMenuProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (action: () => void) => {
    return () => {
      handleClose()
      action && action()
    }
  }

  return (
    <>
      <IconButton
        id="popover-button"
        aria-controls={anchorEl ? 'popover-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="popover-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'popover-button',
        }}
      >
        {onSetMainAccount && (
          <MenuItem onClick={handleMenuItemClick(onSetMainAccount)}>{t('accounts.set_as_main_account')}</MenuItem>
        )}
        {onChangeAccount && (
          <MenuItem onClick={handleMenuItemClick(onChangeAccount)}>{t('accounts.change_to_account')}</MenuItem>
        )}
        {onDeleteAccount && (
          <MenuItem onClick={handleMenuItemClick(onDeleteAccount)} sx={{ color: theme.palette.error.main }}>
            {t('accounts.delete_account')}
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default DtpMenu
