import { PaidInvoicesIcon } from '@assets/icons'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const DtpNoOpenInvoices = () => {
  const { t } = useTranslation()
  return (
    <Stack alignItems="center" pt={1.5}>
      <Box>
        <PaidInvoicesIcon sx={{ width: 64, height: 64 }} />
      </Box>
      <Typography variant="bodyRegularEmphasized">{t('invoices.invoice_all_paid')}</Typography>
    </Stack>
  )
}

export default DtpNoOpenInvoices
