import dayjs from 'dayjs'

export interface IDownloadFile {
  data: File
  fileType: string
  fileName: string
}

export const createFileName = (
  customAggregation: boolean,
  fileName: string,
  startModalDate: string,
  endModalDate: string,
  timestampStartOfPeriod: string | undefined,
  dateFormat: string
) => {
  if (customAggregation) {
    return `${fileName} - ${dayjs(startModalDate).format('DD.MM.YYYY')} - ${dayjs(endModalDate).format(
      'DD.MM.YYYY'
    )}.csv`
  }
  return `${fileName} - ${dayjs(timestampStartOfPeriod).format(dateFormat)}.csv`
}

export const downloadFile = ({ data, fileType, fileName }: IDownloadFile) => {
  const blob = new Blob([data], { type: fileType })

  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}
