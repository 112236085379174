import { useFeatureToggle } from '@hooks/useFeatureToggle/useFeatureToggle'
import useRoleAccess from '@hooks/useRoleAccess/useRoleAccess'
import React from 'react'

interface IFeatureProps {
  flag: string
  children: React.ReactNode
}

const FeatureToggle = ({ flag, children }: IFeatureProps) => {
  const { features } = useFeatureToggle()
  const { isProduction } = useRoleAccess()
  const isEnabled = features[flag]
  if (isProduction) {
    return !isEnabled ? <>{children}</> : null
  }
  return <>{children}</>
}

export default FeatureToggle
