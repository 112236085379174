import DtpModal from '@components/DtpModal/DtpModal'
import { ISelectItem } from '@components/DtpSelect/DtpSelect'
import { IAddOn } from '@hooks/useLocations/useLocationsData'
import { Typography, Stack, Button, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IDtpInvoiceSettingsModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  contracts: ISelectItem<IAddOn>[]
}

const DtpInvoiceSettingsModal = ({ open, onClose, onConfirm, contracts }: IDtpInvoiceSettingsModalProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <DtpModal
      open={open}
      onClose={onClose}
      title={t('invoices_settings.change_for_mulitple_contracts')}
      sx={{
        '.MuiPaper-root': {
          padding: { xs: theme.spacing(3, 4), sm: theme.spacing(6, 8) },
          width: '100%',
          maxWidth: 784,
          [theme.breakpoints.down('sm')]: {
            maxHeight: 'none',
            height: '100%',
            margin: 0,
          },
        },
      }}
    >
      <Stack mt={4} height="100%">
        <Typography variant="bodyRegularEmphasized" fontWeight={400} fontFamily={'GothamBook'}>
          {t('invoices_settings.change_for_mulitple_contracts_explanation')}
        </Typography>
        {contracts.map(({ value, label }) => (
          <Typography
            variant="bodyRegularEmphasized"
            fontWeight={400}
            fontFamily={'GothamBook'}
            key={value}
            sx={{ my: 2 }}
          >
            &bull; {label}
          </Typography>
        ))}
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} mb={{ xs: 2, sm: 0 }} mt={{ xs: 'auto', sm: 2 }}>
          <Button onClick={onConfirm} size="small">
            {t('invoices_settings.confirm')}
          </Button>
          <Button variant="outlined" size="small" onClick={onClose}>
            {t('cancel')}
          </Button>
        </Stack>
      </Stack>
    </DtpModal>
  )
}

export default DtpInvoiceSettingsModal
