import { chartPallete } from '@assets/themes/palette'
import theme from '@assets/themes/theme'

interface IAverageConsumptionLabel {
  dy?: number | string
  value?: number | string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  viewBox?: any
}

const AverageConsumptionLabel = ({ dy, value, viewBox: { x, y, width } }: IAverageConsumptionLabel) => {
  const rectHeight = 29
  const transform = `translate(${x + Number(dy)} ${y - rectHeight / 2})`
  const isMobile = width <= 600

  const primaryColor = chartPallete.bar.averageConsumption.fill
  const strokeColor = chartPallete.bar.averageConsumption.stroke
  const backgroundColor = theme.palette.background.paper

  const fontFamily = isMobile ? 'Montserrat' : 'GothamMedium'
  const fontWeight = isMobile ? 'bold' : '500'
  const fontSize = isMobile ? 12 : 14
  const opacity = isMobile ? 0.95 : 1

  const rectWidth = Math.max(String(value).length * 9 + 18 + 12, 64)

  return (
    <g transform={transform}>
      <rect
        width={rectWidth}
        height={rectHeight}
        rx={8}
        fill={backgroundColor}
        stroke={strokeColor}
        strokeWidth={1}
        opacity={opacity}
        style={{ filter: 'drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1))' }}
      />
      <text
        x={rectWidth / 2}
        y={rectHeight / 2}
        fill={primaryColor}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={fontSize}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
      >
        <tspan> Ø {value} </tspan>
      </text>
    </g>
  )
}

export default AverageConsumptionLabel
