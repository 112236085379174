import { ArrowLeftIcon } from '@assets/icons'
import { Container, Button, Typography, Stack, SxProps } from '@mui/material'
import { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router'

interface IInvoiceSettingsWrapperProps extends PropsWithChildren {
  buttonTitle: string
  link: string
  title: string
  sx?: SxProps
}

const InvoiceSettingsWrapper = ({ buttonTitle, link, title, children, sx }: IInvoiceSettingsWrapperProps) => {
  const navigate = useNavigate()
  return (
    <Container sx={{ pt: { xs: 1, sm: 5 } }}>
      <Stack sx={sx}>
        <Button
          variant="text"
          disableTouchRipple
          size="large"
          sx={{ justifyContent: 'start', pl: '0 !important', maxWidth: 'fit-content' }}
          onClick={() => navigate(link)}
          startIcon={<ArrowLeftIcon />}
        >
          {buttonTitle}
        </Button>

        <Typography variant="heading2">{title}</Typography>
        {children}
      </Stack>
    </Container>
  )
}

export default InvoiceSettingsWrapper
