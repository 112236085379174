import { ISelectItem } from '@components/DtpDropdown/DtpDropdown'
import { IAddOn } from '@hooks/useLocations/useLocationsData'
import { IInvoice } from '@interfaces/invoices'

export const filterContracts = (
  invoices: [string, IInvoice[]][],
  selectedItem: ISelectItem<IAddOn>
): [string, IInvoice[]][] => {
  if (selectedItem.value === '0') {
    return invoices
  }

  return invoices
    .map(([year, invoiceList]) => {
      const filteredList = invoiceList.filter((invoice) => invoice.contractId.includes(selectedItem.value))
      return [year, filteredList] as [string, IInvoice[]]
    })
    .filter(([, filteredList]) => filteredList.length > 0)
}
