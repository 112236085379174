import { ProsumerIcon } from '@assets/icons'
import { deliveryType } from '@enums/deliveryType'
import { MenuItem, Typography, Box, Stack } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'

import { IMenuItemProps } from '../DtpDropdown'

const contractPeriod = (startDate?: string, endDate?: string) =>
  `${dayjs(startDate).format('DD.MM.YYYY')} - ${dayjs(endDate).format('DD.MM.YYYY')}`

export const ExpiredMenuItem = ({ item, handleClick }: IMenuItemProps) => {
  return (
    <MenuItem
      id={item.value}
      value={item.value}
      onClick={() => handleClick?.(item)}
      sx={{
        '&.MuiMenuItem-root': {
          display: 'list-item',
          pl: 2,
          my: 1,
        },
      }}
    >
      <Stack flexDirection="row" alignItems="flex-start" justifyContent="space-between">
        <Box>
          <Typography noWrap fontWeight={500} fontFamily={'GothamMedium'}>
            {item.label}
          </Typography>
          <Typography noWrap fontFamily={'GothamBook'} fontWeight={400}>
            {contractPeriod(item.addOn?.supplyStartDate, item.addOn?.supplyEndDate)}
          </Typography>
        </Box>
        <Show when={item.addOn?.deliveryType === deliveryType.FEEDIN}>
          <ProsumerIcon sx={{ width: '20px', height: '20px' }} />
        </Show>
      </Stack>
    </MenuItem>
  )
}
