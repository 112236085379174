import DtpError from '@components/DtpError/DtpError'
import { Button, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const CriticalError = () => {
  const { t } = useTranslation()

  const handleReload = () => window.location.reload()

  return (
    <Box display="flex" alignItems="center" height="100%">
      <DtpError
        image
        title={t('http_errors.error_headline')}
        subtitle={t('http_errors.error_try_later_again')}
        slot={<Button onClick={handleReload}>{t('reload')}</Button>}
        sx={{ textAlign: { xs: 'center', sm: 'left' }, alignItems: 'center' }}
      />
    </Box>
  )
}

export default CriticalError
