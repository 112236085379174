import { useAuth } from '@hooks/useAuth/useAuth'
import { ArrowForward } from '@mui/icons-material'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

const LoginSecurityCard = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { getKeycloakAccountPage } = useAuth()
  return (
    <Box
      py={1}
      px={2}
      border={`1px solid ${theme.palette.khaki['200']}`}
      bgcolor={theme.palette.khaki['50']}
      borderRadius={3}
    >
      <Typography variant="bodyRegular">{t('personal_data.change_login_data')}</Typography>
      <Box ml={-1} mt={-1}>
        <Button
          variant="text"
          size="small"
          disableTouchRipple
          onClick={getKeycloakAccountPage}
          endIcon={<ArrowForward />}
        >
          {t('login_security')}
        </Button>
      </Box>
    </Box>
  )
}

export default LoginSecurityCard
