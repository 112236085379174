import { CloseButtonIcon } from '@assets/icons'
import { Box, Button, Stack, Typography, useMediaQuery, useTheme, CircularProgress } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'

interface IDtpErrorMessage {
  onClose?: () => void
  onConfirm?: () => void
  errorTitle?: string
  errorDescription?: string
  isLoading?: boolean
}

const DtpErrorMessage = ({ onClose, onConfirm, errorTitle, errorDescription, isLoading = false }: IDtpErrorMessage) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Stack direction="column" justifyContent="space-between" p={isMobile ? 0 : 2} height="100%" gap={2}>
      <Box textAlign="center" height="100%">
        <CloseButtonIcon sx={{ width: '60px', height: '60px', m: '6px' }} />
        <Typography variant="heading4">{errorTitle ?? t('http_errors.error_headline')}</Typography>
        <Typography variant="bodyRegular">{errorDescription ?? t('http_errors.error_try_later_again')}</Typography>
      </Box>
      <Stack direction={isMobile ? 'column' : 'row'} spacing={2} justifyContent="center">
        <Button sx={{ height: '48px', fontSize: { xs: '18px' } }} onClick={onConfirm} disabled={isLoading}>
          <Show when={isLoading}>
            <CircularProgress size={30} color="inherit" />
          </Show>
          <Show when={!isLoading}>{t('button_try_again')}</Show>
        </Button>
        <Button onClick={onClose} variant="outlined" sx={{ height: '48px', fontSize: { xs: '18px' } }}>
          {t('button_close')}
        </Button>
      </Stack>
    </Stack>
  )
}

export default DtpErrorMessage
