import { Box, SxProps } from '@mui/material'

const Dot = ({ color, sx }: { color?: string; sx?: SxProps }) => {
  return (
    <Box
      sx={{
        marginBottom: 0.25,
        marginRight: 0.5,
        borderRadius: '100%',
        bgcolor: color,
        ...sx,
      }}
    />
  )
}

export default Dot
