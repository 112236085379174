import DtpGroupContracts from '@components/DtpGroupContracts/DtpGroupContracts'
import { SuspenseLoading } from '@components/SuspenseLoading/SuspenseLoading'
import useLocationsData from '@hooks/useLocations/useLocationsData'
import { Container, Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Contracts = () => {
  const { t } = useTranslation()
  const { activeContracts, expiredContracts, isLoading, isError } = useLocationsData()

  if (isLoading) return <SuspenseLoading />

  if (isError)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          textAlign: 'center',
        }}
      >
        <Typography variant="heading2">{t('error_no_contract_details')}</Typography>
      </Box>
    )
  return (
    <Container>
      <Typography variant="heading3" sx={{ mt: { xs: 1.5, md: 6 }, mb: 4 }}>
        {t('contracts.my_contracts')}
      </Typography>

      <DtpGroupContracts contracts={activeContracts} contractStatus="active" />
      <DtpGroupContracts contracts={expiredContracts} contractStatus="terminated" />
    </Container>
  )
}

export default Contracts
