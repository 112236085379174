import { CalendarIcon } from '@assets/icons'
import { isDateValid } from '@helpers/dateFunctions'
import { Button, useTheme, useMediaQuery } from '@mui/material'
import dayjs from 'dayjs'

interface IDtpDateButtonProps {
  startDate: string
  endDate: string
  onClick: () => void
}

const DtpDateButton = ({ onClick, startDate, endDate }: IDtpDateButtonProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const dateFormat = isMobile ? 'DD.MM.YYYY' : 'DD. MMMM YYYY'

  return (
    <Button
      onClick={onClick}
      variant="text"
      size="small"
      sx={{ color: theme.palette.text.primary }}
      startIcon={<CalendarIcon sx={{ mr: 1, fill: 'transparent' }} />}
    >
      {`${isDateValid(startDate) ? dayjs(startDate).format(dateFormat) : 'TT.MM.JJJJ'}
    -
    ${isDateValid(endDate) ? dayjs(endDate).format(dateFormat) : 'TT.MM.JJJJ'}`}
    </Button>
  )
}

export default DtpDateButton
