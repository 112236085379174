import { CheckIcon, EBillIcon } from '@assets/icons'
import styled from '@emotion/styled'
import { Box, Card, CardHeader } from '@mui/material'

export interface IDtpReceiptOfInvoiceCardProps {
  title?: string
  description?: string
}

const DtpReceiptOfInvoiceCard = ({ title, description }: IDtpReceiptOfInvoiceCardProps) => {
  const StyledCard = styled(Card)(() => ({
    maxWidth: '550px',
    boxShadow: 'none',
    backgroundColor: '#E7F6F9',
  }))

  return (
    <StyledCard>
      <CardHeader
        avatar={
          <Box sx={{ position: 'relative' }}>
            <EBillIcon sx={{ width: '38.5px', height: '38.5px' }} />
            <CheckIcon sx={{ position: 'absolute', top: '23px', left: '21px', fill: '#6C9C30' }} />
          </Box>
        }
        titleTypographyProps={{
          variant: 'bodyRegular',
          fontWeight: 500,
          fontFamily: 'GothamMedium',
        }}
        subheaderTypographyProps={{
          variant: 'bodySmall',
          fontWeight: 400,
          fontFamily: 'GothamBook',
        }}
        title={title}
        subheader={description}
        sx={{
          '& .MuiCardHeader-action': {
            alignSelf: 'center',
          },
          '.MuiCardHeader-avatar': {
            alignSelf: 'start',
            maxWidth: '50px',
            mt: 1.2,
          },
        }}
      />
    </StyledCard>
  )
}

export default DtpReceiptOfInvoiceCard
