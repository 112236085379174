import useRoleAccess from '@hooks/useRoleAccess/useRoleAccess'
import { Suspense, useMemo } from 'react'
import { useRoutes } from 'react-router-dom'

import { SuspenseLoading } from '../ui/components/SuspenseLoading/SuspenseLoading'
import { routes as routesArray, publicRoutes, maintenanceRoutes } from './routes.config'

export const Routes = () => {
  const { isBetaTester } = useRoleAccess()
  const routes = useRoutes(isBetaTester ? routesArray : maintenanceRoutes)
  const renderRoutes = useMemo(() => routes, [routes])

  return (
    <Suspense fallback={<SuspenseLoading />}>
      <>{renderRoutes}</>
    </Suspense>
  )
}

export const PublicRoutes = () => {
  const routes = useRoutes(publicRoutes)
  const renderRoutes = useMemo(() => routes, [routes])

  return <Suspense fallback={<SuspenseLoading />}>{renderRoutes}</Suspense>
}
