import DtpContractCard, { IDtpContractCardProps } from '@components/DtpContractCard/DtpContractCard'
import { Stack, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'

interface IDtpGroupContractsProps {
  contracts?: IDtpContractCardProps[]
  contractStatus: string
}

const DtpGroupContracts = ({ contracts, contractStatus }: IDtpGroupContractsProps) => {
  const { t } = useTranslation()

  return (
    <Stack rowGap={3}>
      <Show when={contractStatus === 'active' || contractStatus === 'terminated'}>
        <Typography sx={{ my: 0, mt: contractStatus === 'active' ? 0 : 3 }} variant="heading4">
          {contractStatus === 'active' ? t('contracts.contract_active') : t('contracts.contract_expired')}
        </Typography>
      </Show>

      <Stack gap={3} justifyContent="start" direction="row" flexWrap="wrap">
        {contracts?.map(({ contract, address }) => {
          return <DtpContractCard key={contract.contractNumber} address={address} contract={contract} />
        })}
      </Stack>
    </Stack>
  )
}

export default DtpGroupContracts
