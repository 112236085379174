import { InfoIcon, ArrowDiagonalIcon } from '@assets/icons'
import { Button, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'

const DtpInvoiceExplanationLink = () => {
  const { t } = useTranslation()

  return (
    <Link href="https://www.ckw.ch/kontakt/rechnungen-und-zahlungen" target="_blank" sx={{ my: 0 }}>
      <Button
        variant="text"
        size="large"
        sx={{ py: 1, px: 1.5 }}
        startIcon={<InfoIcon />}
        endIcon={<ArrowDiagonalIcon />}
      >
        {t('invoices.link_explanation_invoice')}
      </Button>
    </Link>
  )
}

export default DtpInvoiceExplanationLink
