import theme from '@assets/themes/theme'
import { Stack, useMediaQuery, Divider, Box, Skeleton } from '@mui/material'

const ConsumptionSumSkeleton = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileLarge = useMediaQuery(theme.breakpoints.down('md'))

  const ConsumptionItem = () => {
    return (
      <Box>
        <Skeleton
          animation="wave"
          variant="rounded"
          width={50}
          height={12}
          sx={{ margin: isMobile ? '0 auto 8px auto' : '0 0 8px 0' }}
        />
        <Skeleton animation="wave" variant="rounded" width={88} height={15} />
      </Box>
    )
  }

  return (
    <Stack
      direction="row"
      justifyContent={isMobile ? 'space-evenly' : 'end'}
      alignItems="center"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={isMobileLarge ? 0.5 : 2}
      height={57.8}
      mt={1}
    >
      <ConsumptionItem />
      <ConsumptionItem />
      <ConsumptionItem />
    </Stack>
  )
}

export default ConsumptionSumSkeleton
