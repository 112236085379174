import DtpFormWrapper, { FormProps } from '@components/DtpFormWrapper/DtpFormWrapper'
import { styled } from '@mui/material'
import { DateField, DateFieldProps } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/de'

type ComposedTextFieldProps = FormProps & DateFieldProps<Dayjs>

const CustomDateField = styled((props: ComposedTextFieldProps) => <DateField {...props} variant="standard" />)({
  display: 'block',
  width: '100%',
  '& .MuiInputBase-root': {
    ':before, :after': {
      content: 'none',
    },
    ':hover': {
      ':before': {
        content: 'none',
      },
    },
  },
})

const DtpDateField = (props: ComposedTextFieldProps) => {
  const { helperText, label, value, error, minDate, disabled, maxDate, onChange, onBlur, fullWidth } = props
  const lng = localStorage.getItem('i18nextLng')

  dayjs.locale(lng === 'de' ? 'de' : '')
  const dateFormat = 'DD. MM. YYYY'

  return (
    <DtpFormWrapper helperText={helperText} label={label} fullWidth={fullWidth}>
      <CustomDateField
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        format={dateFormat}
        error={error}
      />
    </DtpFormWrapper>
  )
}

export default DtpDateField
