import { Box, Typography } from '@mui/material'

const Support = () => {
  return (
    <Box>
      <Typography>Support</Typography>
    </Box>
  )
}

export default Support
