import ErrorIcon from '@mui/icons-material/Error'
import { Box, Button, Typography, useTheme, CircularProgress } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'

interface IDtpErrorCardProps {
  action: () => void
  isLoading?: boolean
  title?: string
  description?: string
}

const DtpErrorCard = ({ action, title, description, isLoading }: IDtpErrorCardProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Box
      p={4}
      bgcolor={theme.palette.grey['50']}
      borderRadius={'16px'}
      maxWidth={416}
      textAlign="center"
      overflow={'hidden'}
    >
      <Typography variant="bodyRegularEmphasized">
        <ErrorIcon sx={{ verticalAlign: 'middle' }} /> {title ?? t('http_errors.loading_error_title')}
      </Typography>
      <Typography variant="bodySmall" fontFamily={'GothamBook'} fontWeight={400}>
        {description ?? t('http_errors.loading_error_description')}
      </Typography>
      <Button variant="contained" sx={{ marginTop: 2 }} onClick={action} disabled={!!isLoading}>
        <Show when={!!isLoading}>
          <CircularProgress size={30} color="inherit" />
        </Show>
        <Show when={!isLoading}>{t('button_try_again')}</Show>
      </Button>
    </Box>
  )
}

export default DtpErrorCard
