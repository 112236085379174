import { setMainAccount } from '@api/mutations'
import DtpAccountCard from '@components/DtpAccountCard/DtpAccountCard'
import DtpErrorModal from '@components/DtpErrorModal/DtpErrorModal'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { IAccount } from '@interfaces/accounts'
import { Container, Typography, Grid } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useMutation } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const ManagedAccounts = () => {
  const [open, setOpen] = useState(false)
  const [selectedGpnr, setSelectedGpnr] = useState('')

  const { t } = useTranslation()
  const { accounts, activeAccount, switchAccount, refetch } = useAccounts()

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: setMainAccount,
    onSuccess: async () => {
      await refetch()
      setOpen(false)
    },
  })

  const handleSetMainAccount = async (gpnr: string) => {
    try {
      await mutateAsync({ gpnr })
    } catch (error) {
      setSelectedGpnr(gpnr)
      setOpen(true)
    }
  }

  const [mainAccount, standardAccounts] = useMemo(() => {
    return accounts.reduce<[IAccount[], IAccount[]]>(
      (acc, account) => {
        account.mainAccount ? acc[0].push(account) : acc[1].push(account)
        return acc
      },
      [[], []]
    )
  }, [accounts])

  return (
    <>
      <Container>
        <Typography variant="heading3" sx={{ mt: { xs: 1.5, md: 9 }, mb: 4 }}>
          {t('navigation.navi_managed_accounts')}
        </Typography>

        <Show when={!!mainAccount.length}>
          <Typography variant="leadEmphasized" sx={{ marginBottom: 5 }}>
            {t('accounts.main_account')}
          </Typography>
          {mainAccount.map((account: IAccount) => (
            <DtpAccountCard
              key={account.gpnr}
              {...account}
              active={account.gpnr === activeAccount.gpnr}
              onChangeAccount={() => switchAccount(account)}
              onDeleteAccount={() => {}}
            />
          ))}
        </Show>

        <Show when={!!standardAccounts.length}>
          <Typography variant="leadEmphasized" sx={{ marginBottom: 0, marginTop: 5 }}>
            {t('accounts.additional_accounts')}
          </Typography>
          <Grid container columnGap={3} mb={2}>
            {standardAccounts.map((account: IAccount) => (
              <Grid key={account.gpnr} item xs={12} md="auto">
                <DtpAccountCard
                  {...account}
                  active={account.gpnr === activeAccount.gpnr}
                  onChangeAccount={() => switchAccount(account)}
                  onSetMainAccount={() => handleSetMainAccount(account.gpnr)}
                  onDeleteAccount={() => {}}
                />
              </Grid>
            ))}
          </Grid>
        </Show>
      </Container>
      <DtpErrorModal
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => handleSetMainAccount(selectedGpnr)}
        isLoading={isLoading}
      />
    </>
  )
}

export default ManagedAccounts
