import { updateAccountFields } from '@api/mutations'
import { getAccountDetails } from '@api/queries'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { IAccountDetails } from '@interfaces/accounts'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

export const useAccountDetails = () => {
  const { activeAccount } = useAccounts()
  const gpnr = activeAccount.gpnr

  const {
    data,
    isLoading,
    refetch: refetchAccountDetails,
  } = useQuery({
    queryKey: ['accountDetails', gpnr],
    queryFn: () => getAccountDetails(gpnr),
    enabled: !!gpnr,
  })

  const accountData = useMemo(() => data?.body, [data])

  const { mutateAsync: updateAccountDetails, isLoading: isUpdatingAccountDetails } = useMutation({
    mutationFn: (updatedDetails: IAccountDetails) => updateAccountFields({ gpnr, updatedDetails }),
  })

  return { accountData, isLoading, updateAccountDetails, isUpdatingAccountDetails, refetchAccountDetails }
}
