import i18next from 'i18next'
import * as yup from 'yup'

const { t } = i18next

export const accountNumberSchema = yup.object({
  accountNumber: yup
    .string()
    .test('max-ten-digits', t('authorization.authorization_invalid_input'), (value) => {
      if (!value) return false
      const digitCount = value.replace(/\s/g, '').length
      return /^\d+$/.test(value.replace(/\s/g, '')) && digitCount <= 10
    })
    .required(t('authorization.authorization_invalid_input')),
})

export const accessCodeSchema = yup.object({
  accessCode: yup
    .string()
    .test('max-six-letters-digits', t('authorization.authorization_invalid_input'), (value) => {
      if (!value) return false
      const strippedValue = value.replace(/\s/g, '')
      const isValidFormat = /^[a-zA-Z0-9]+$/.test(strippedValue)
      return isValidFormat && strippedValue.length <= 6
    })
    .required(t('authorization.authorization_invalid_input')),
})
