import { Box, Typography, useTheme } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'

export const ContactInfoField = ({
  title,
  content,
  additionalContent,
  emptyContentMessage,
}: {
  title: string
  content?: string | null
  additionalContent?: string
  emptyContentMessage?: string
}) => {
  const theme = useTheme()
  return (
    <Box>
      <Typography sx={{ my: 0, color: theme.palette.text.secondary, fontSize: { xs: '14px' } }} variant="caption">
        {title}
      </Typography>
      <Box my={2}>
        <Show when={!!content}>
          <Typography sx={{ my: 0, fontSize: { xs: '16px' } }} variant="bodySmall">
            {content}
          </Typography>
        </Show>
        <Show when={!!additionalContent}>
          <Typography sx={{ my: 0, fontSize: { xs: '16px' } }} variant="bodySmall">
            {additionalContent}
          </Typography>
        </Show>
        <Show when={!!emptyContentMessage && !content}>
          <Typography sx={{ my: 0, fontSize: { xs: '16px' }, color: '#6A716A' }} variant="bodySmall">
            {emptyContentMessage}
          </Typography>
        </Show>
      </Box>
    </Box>
  )
}
