import DtpTextField from '@components/DtpTextField/DtpTextField'
import { Box, Stack, Button, Typography } from '@mui/material'
import { useMask } from '@react-input/mask'
import { accountNumberSchema } from '@src/validations/authorizationValidationSchema'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { FormSteps, IStepperProps } from './DtpAuthCard'
import TooltipLabel from './TooltipLabel'

const AccountNumberForm = ({ setStep }: IStepperProps) => {
  const { t } = useTranslation()

  const accountNumberRef = useMask({ mask: '_ ___ ___ ___', replacement: '_' })

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    validationSchema: accountNumberSchema,
    initialValues: {
      accountNumber: '',
    },
    onSubmit: () => setStep(FormSteps.ACCESS_CODE_STEP),
  })

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={4}>
        <Typography variant="heading3">{t('authorization.connect_account')}</Typography>
        <Typography variant="bodyRegular">{t('authorization.authorization_explanation')}</Typography>
      </Box>

      <DtpTextField
        value={values.accountNumber}
        onChange={handleChange}
        name="accountNumber"
        inputRef={accountNumberRef}
        label={
          <TooltipLabel
            label={t('authorization.auth_customer_number')}
            tooltipLabel={t('authorization.tooltip_authorization_customer_number')}
          />
        }
        inputMode="tel"
        helperText={touched.accountNumber ? errors.accountNumber : ''}
        error={touched.accountNumber && Boolean(errors.accountNumber)}
        placeholder="x xxx xxx"
        fullWidth
      />

      <Box mt={4}>
        <Button type="submit" size="large">
          {t('authorization.connect_account')}
        </Button>
        <Stack direction="row" alignItems="center" mt={3} flexWrap="wrap" columnGap={1}>
          <Typography variant="bodyRegular" sx={{ m: 0 }}>
            {t('authorization.no_customer_number')}
          </Typography>
          <Button variant="text" size="large" sx={{ p: '0 !important' }} onClick={() => setStep(FormSteps.SUPPORT)}>
            {t('authorization.contact_support')}
          </Button>
        </Stack>
      </Box>
    </form>
  )
}

export default AccountNumberForm
