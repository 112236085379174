import { SettingsIcon } from '@assets/icons'
import DtpInvoiceCardGroup from '@components/DtpInvoiceCardGroup/DtpInvoiceCardGroup'
import DtpInvoiceExplanationLink from '@components/DtpInvoiceExplanationLink/DtpInvoiceExplanationLink'
import DtpNoOpenInvoices from '@components/DtpNoOpenInvoices/DtpNoOpenInvoices'
import DtpPaidInvoiceAccordionGroup from '@components/DtpPaidInvoiceAccordionGroup/DtpPaidInvoiceAccordionGroup'
import InvoicePageSkeleton from '@components/Skeletons/InvoicePageSkeleton'
import { useInvoices } from '@hooks/useInvoices/useInvoices'
import useLocationsData from '@hooks/useLocations/useLocationsData'
import { Button, Container, Divider, Stack, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const Invoices = () => {
  const { t } = useTranslation()
  const { activeLocations, expiredLocations, isLoading: isLoadingLocations } = useLocationsData()
  const { invoices: openInvoicesData, isLoading: isLoadingOpenInvoices } = useInvoices({ status: 'OPEN' })
  const { invoices: paidInvoicesData, isLoading: isLoadingPaidInvoices } = useInvoices({ status: 'PAID' })
  const navigate = useNavigate()
  const openInvoices = Object.values(openInvoicesData).flat()
  const paidInvoices = Object.entries(paidInvoicesData).reverse()

  if (isLoadingOpenInvoices || isLoadingPaidInvoices || isLoadingLocations) return <InvoicePageSkeleton />

  return (
    <Container>
      <Typography variant="heading2" sx={{ mt: { xs: 1.5, sm: 5, md: 10 }, mb: { xs: 1, sm: 3, md: 6 } }}>
        {t('contracts.invoices')}
      </Typography>

      <DtpInvoiceCardGroup invoices={openInvoices} />

      <Show when={!openInvoices.length}>
        <DtpNoOpenInvoices />
      </Show>

      <Divider sx={{ mb: 2 }} />

      <Stack width="fit-content" gap={0} ml={-1.5} sx={{ mb: { xs: 2, sm: 5 } }}>
        <Button
          variant="text"
          size="large"
          sx={{ justifyContent: 'start', pl: 1.5 }}
          onClick={() => navigate(t('navigation_url.invoices_settings'))}
          startIcon={<SettingsIcon />}
        >
          {t('navigation.navi_invoices_settings')}
        </Button>
        <DtpInvoiceExplanationLink />
      </Stack>

      <Show when={!!paidInvoices.length}>
        <DtpPaidInvoiceAccordionGroup
          activeLocations={activeLocations}
          expiredLocations={expiredLocations}
          invoices={paidInvoices}
        />
      </Show>
    </Container>
  )
}

export default Invoices
