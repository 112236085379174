export const hasValue = (value: number | string | null | undefined): boolean => {
  if (typeof value === 'number') {
    return value > 0
  }

  if (typeof value === 'string') {
    const parsedValue = parseFloat(value)
    return !isNaN(parsedValue) && parsedValue > 0
  }

  return false
}
