import { IMeasurement } from '@interfaces/measurements'

export interface IAreaMouseClickHelperProps {
  e: { chartX?: number; chartY?: number }
  isMobile: boolean
  isTablet: boolean
  isSmallMobile: boolean
  viewportWidth: number
  TOOLTIP_INITIAL_X: number
  TOOLTIP_INITIAL_Y: number
  setTooltipPosition?: CallableFunction
  tooltipOpen: boolean
  setTooltipOpen: CallableFunction
}

export const handleSetTooltipPositionAndOpenArea = ({
  e,
  isMobile,
  isTablet,
  isSmallMobile,
  viewportWidth,
  tooltipOpen,
  TOOLTIP_INITIAL_X,
  TOOLTIP_INITIAL_Y,
  setTooltipOpen,
  setTooltipPosition,
}: IAreaMouseClickHelperProps) => {
  const offset = isMobile || isTablet ? 218 : 287
  const chartX = e.chartX ?? 0
  const tooltipPositionX = chartX - offset
  const xTooltipPositionSecondStatemant = tooltipPositionX > 0 ? tooltipPositionX : tooltipPositionX + 143
  if (isSmallMobile) {
    setTooltipPosition?.({ x: TOOLTIP_INITIAL_X, y: TOOLTIP_INITIAL_Y })
  } else {
    setTooltipPosition?.({
      x: chartX + offset < Math.min(viewportWidth, 1100) ? chartX : xTooltipPositionSecondStatemant,
      y: TOOLTIP_INITIAL_Y,
    })
  }
  !tooltipOpen && setTooltipOpen(true)
}

export const calculateMaxMinDomain = (data: IMeasurement[]) => {
  const sumOfRates = data.map((item) => Number(item.sumOfRates)).filter((item) => !Number.isNaN(item))
  const prosumerVals = data.map((item) => Number(item.feedIn)).filter((item) => !Number.isNaN(item))
  const consumptionVals = data.map((item) => Number(item.consumption)).filter((item) => !Number.isNaN(item))
  const max = Math.max(...sumOfRates, ...prosumerVals, ...consumptionVals)
  const min = Math.min(...sumOfRates, ...prosumerVals, ...consumptionVals)
  return { min, max }
}

export const calculateTicks = (minValue: number, maxValue: number, tickCount: number) => {
  const range = maxValue - minValue

  if (minValue === maxValue) return [maxValue]

  const interval = range / (tickCount - 1)

  const ticks = []
  if (maxValue / tickCount <= 0.01) {
    ticks.push(0, maxValue)
  } else {
    for (let i = 0; i < tickCount; i++) {
      const tick = minValue + interval * i
      ticks.push(tick.toFixed(2))
    }
  }
  return ticks
}

export type IObject =
  | {
      [Property in keyof IMeasurement]: string | string[]
    }
  | { [key: string]: string | string[] | IObject }

export const objectsEqual = (o1: IObject | IMeasurement, o2: IObject | IMeasurement): boolean => {
  const keys1 = Object.keys(o1) as (keyof IObject | keyof IMeasurement)[]
  const keys2 = Object.keys(o2) as (keyof IObject | keyof IMeasurement)[]
  if (keys1.length !== keys2.length) return false

  for (const key of keys1) {
    if (o1[key] !== o2[key]) return false
  }
  return true
}

export const arraysEqual = (a1: IObject[] | IMeasurement[], a2: IObject[] | IMeasurement[]) => {
  if (a1.length !== a2.length) {
    return false
  }
  for (let i = 0; i < a1.length; i++) {
    if (!objectsEqual(a1[i], a2[i])) {
      return false
    }
  }
  return true
}
