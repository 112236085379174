import { ProsumerIcon } from '@assets/icons'
import { deliveryType } from '@enums/deliveryType'
import { MenuItem, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'

import { IMenuItemProps } from '../DtpDropdown'

export const activeMenuSubItemStyles = {
  '&.MuiMenuItem-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    py: 1.25,
    pl: 5,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '23px',
      top: '50%',
      transform: 'translateY(-60%)',
      width: 5,
      height: 5,
      borderRadius: '50%',
      backgroundColor: '#303636',
    },
  },
}

export const ActiveMenuSubItem = ({ item, handleClick }: Readonly<IMenuItemProps>) => {
  return (
    <MenuItem
      key={item.value}
      id={item.value}
      value={item.value}
      onClick={() => handleClick?.(item)}
      sx={activeMenuSubItemStyles}
    >
      <Typography noWrap sx={{ pl: 0 }}>
        {item.label}
      </Typography>
      <Show when={item.addOn?.deliveryType === deliveryType.FEEDIN}>
        <ProsumerIcon sx={{ width: '20px', height: '20px' }} />
      </Show>
    </MenuItem>
  )
}
