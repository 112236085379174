import { Skeleton, Box, Stack } from '@mui/material'

const DtpInvoiceSettingsCardSkeleton = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      maxWidth={550}
      px={3}
      py={'16.41px'}
      mt={1}
      width="100%"
      sx={{ border: '1px solid #EFF0EF', borderRadius: '8px' }}
    >
      <Stack direction="row" flex={1} columnGap={2}>
        <Box mt={1}>
          <Skeleton width={24} height={24} variant="circular" />
        </Box>
        <Box flex={1}>
          <Skeleton width="60%" height={28} />
          <Skeleton width="50%" height={29} sx={{ my: '7px' }} />
        </Box>
      </Stack>
      <Box>
        <Skeleton width={34} height={24} />
      </Box>
    </Stack>
  )
}

export default DtpInvoiceSettingsCardSkeleton
