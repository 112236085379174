import Show from '@src/ui/wrappers/Show/Show'
import { useState } from 'react'

import AccessCodeForm from './AccessCodeForm'
import AccountNumberForm from './AccountNumberForm'
import AuthCardWrapper from './AuthCardWrapper'
import RequestNewCode from './RequestNewCode'
import Support from './Support'

export enum FormSteps {
  ACCOUNT_NUMBER_STEP = 'account-number',
  ACCESS_CODE_STEP = 'access-code',
  NEW_CODE_STEP = 'new-code',
  SUPPORT = 'contact-support',
}

export interface IStepperProps {
  setStep: (step: FormSteps) => void
}

const DtpAuthCard = () => {
  const [step, setStep] = useState<FormSteps>(FormSteps.ACCOUNT_NUMBER_STEP)

  return (
    <AuthCardWrapper>
      <Show when={step === FormSteps.ACCOUNT_NUMBER_STEP}>
        <AccountNumberForm setStep={setStep} />
      </Show>
      <Show when={step === FormSteps.ACCESS_CODE_STEP}>
        <AccessCodeForm setStep={setStep} />
      </Show>
      <Show when={step === FormSteps.NEW_CODE_STEP}>
        <RequestNewCode setStep={setStep} />
      </Show>
      <Show when={step === FormSteps.SUPPORT}>
        <Support />
      </Show>
    </AuthCardWrapper>
  )
}

export default DtpAuthCard
