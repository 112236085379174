import { DownloadIcon, DocumentIcon } from '@assets/icons'
import theme from '@assets/themes/theme'
import DtpInfoTooltip from '@components/DtpInfoTooltip/DtpInfoTooltip'
import { getFormattedBillingPeriod } from '@helpers/invoices'
import { IInvoice } from '@interfaces/invoices'
import { Box, Typography, Stack } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { CustomTableCell, CustomDownloadButton } from './DtpPaidInvoiceAccordionUtills'

interface IInvoiceRowDesktopProps {
  row: IInvoice
  multipleContracts: boolean
  onDownloadPdf: (invoiceId: string) => void
}

const InvoiceRowDesktop = ({ row, multipleContracts, onDownloadPdf }: IInvoiceRowDesktopProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isCreditNote = row?.amountTotal?.includes('-')
  const billingPeriod = getFormattedBillingPeriod(row.billingPeriodFromDate, row.billingPeriodToDate)
  return (
    <>
      <CustomTableCell>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          columnGap="24px"
          onClick={() => navigate(`${t('navigation_url.invoices')}/${row.invoiceId}`)}
        >
          <DocumentIcon sx={{ width: 48, height: 48, fill: '#EDEEED' }} />
          <Box>
            <Typography variant="bodySmallEmphasized" sx={{ margin: 0 }}>
              {`${t(isCreditNote ? 'invoices.invoice_payout' : 'invoices.invoice')} ${billingPeriod}`}
            </Typography>
            <Show when={multipleContracts || row.contractId.length > 1}>
              <Typography
                variant="bodySmall"
                sx={{ margin: 0, fontFamily: 'GothamBook', fontWeight: 400, color: theme.palette.text.secondary }}
                maxWidth={300}
                noWrap
              >
                {row.contractId.map((contract, index) => (index === 0 ? contract : ` | ${contract}`))}
              </Typography>
            </Show>
          </Box>
        </Stack>
      </CustomTableCell>
      <CustomTableCell>
        <Typography
          variant="bodySmall"
          sx={{
            fontFamily: 'GothamBook',
            fontWeight: 400,
            color: theme.palette.text.secondary,
          }}
        >
          {row.invoiceId}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        {/* check for empty state */}
        <Show when={!row.amountTotal}>
          <Typography variant="bodySmallEmphasized" color={isCreditNote ? theme.palette.primary.main : 'inherit'}>
            -
          </Typography>
        </Show>
        <Show when={!!row.amountTotal}>
          <Typography
            id="creditNoteExplanation"
            variant="bodySmallEmphasized"
            color={isCreditNote ? theme.palette.primary.main : 'inherit'}
          >
            {isCreditNote ? (
              <>
                {row.amountTotal} {row.currency}
                <DtpInfoTooltip
                  placement="top"
                  iconSize={18}
                  iconButtonSx={{
                    mb: 0.5,
                    ml: 0.5,
                    color: theme.palette.text.secondary,
                  }}
                  id="creditNoteExplanation"
                  title={t('invoices.credit_note_explanation_title')}
                  content={
                    <Typography variant="bodyRegular" color="textPrimary">
                      {t('invoices.credit_note_explanation')}
                    </Typography>
                  }
                />
              </>
            ) : (
              `${row.amountTotal} ${row.currency}`
            )}
          </Typography>
        </Show>
      </CustomTableCell>
      <CustomTableCell>
        <CustomDownloadButton
          disableRipple
          size="medium"
          variant="text"
          onClick={() => onDownloadPdf(row.invoiceId)}
          startIcon={<DownloadIcon />}
        >
          PDF
        </CustomDownloadButton>
      </CustomTableCell>
    </>
  )
}

export default InvoiceRowDesktop
