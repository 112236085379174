import { Typography, MenuItem } from '@mui/material'

import { IMenuItemProps } from '../DtpContractDropdown'

export const ActiveMenuItem = ({ item, handleClick }: Readonly<IMenuItemProps>) => {
  return (
    <MenuItem
      key={item.value}
      id={item.value}
      value={item.value}
      onClick={() => handleClick(item)}
      sx={{
        '&.MuiMenuItem-root': {
          py: 1.25,
          borderTop: '1px solid #D5D8D5',
        },
        '&.MuiMenuItem-root:nth-of-type(3)': {
          borderTop: '1px solid transparent',
        },
      }}
    >
      <Typography noWrap>{item.label}</Typography>
    </MenuItem>
  )
}
