import { comparisonPalette, prosumerPalette } from '@assets/themes/palette'
import { IMeasurement } from '@interfaces/measurements'
import { useTranslation } from 'react-i18next'

export interface ICostumizedBarProps {
  x?: number
  y?: number
  width?: number
  height?: number
  payload?: IMeasurement
  aggregationType?: 'month' | 'year'
  isMobile: boolean
}

export const CustomizedBar = (props: ICostumizedBarProps) => {
  const { x, y, width, height, payload, aggregationType, isMobile } = props
  const { t } = useTranslation()

  if (!x || !y || !width || !height || !payload) {
    const centerX = x && width ? x + width / 2 : 200
    const centerY = y && height ? y + height / 2 : 100
    const noDataStringArray = String(
      t('power_consumption_page.error_message_no_data_available') +
        ', ' +
        (aggregationType === 'year'
          ? t('power_consumption_page.comparison_no_data_year')
          : t('power_consumption_page.comparison_no_for_combination'))
    ).split(' ')

    const noDataStringRender = noDataStringArray.map((string, index, array) => {
      if (isMobile && index % 2 === 0) {
        return (
          <text
            key={index}
            x={centerX}
            y={centerY - 25}
            dy={index === 0 ? -25 : -25 + index * 10}
            fill="#4F544F"
            textAnchor="middle"
            fontSize={14}
            dominantBaseline="middle"
          >
            {array.slice(index, index + 2).join(' ')}
          </text>
        )
      } else if (!isMobile && index % 3 === 0) {
        return (
          <text
            key={index}
            x={centerX}
            y={centerY - 25}
            dy={index === 0 ? -25 : -25 + index * 10}
            fill="#4F544F"
            textAnchor="middle"
            fontSize={16}
            dominantBaseline="middle"
          >
            {array.slice(index, index + 3).join(' ')}
          </text>
        )
      }
    })
    return <>{noDataStringRender}</>
  }

  if (height && height < 0) {
    return (
      <path
        x={x}
        y={y}
        width={width}
        height={height}
        radius="0"
        fill={prosumerPalette.prosumerFeedInNegative}
        className="recharts-rectangle"
        d={`M ${x},${y} h ${width} v ${height} h ${-width} Z`}
      ></path>
    )
  } else {
    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={
          payload?.feedIn && Number(payload?.feedIn) > 0
            ? prosumerPalette.prosumerFeedIn
            : comparisonPalette.consumption.fill
        }
      />
    )
  }
}
