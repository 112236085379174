import { FacebookIcon, InstagramIcon, LinkedinIcon, YoutubeIcon } from '@assets/icons'
import { Container, Divider, IconButton, Stack, Link, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const legalLinks = [
  {
    name: 'terms_and_conditions',
    path: 'https://www.ckw.ch/agb',
  },
  {
    name: 'data_protection',
    path: 'https://www.ckw.ch/datenschutz',
  },
  {
    name: 'imprint',
    path: 'https://www.ckw.ch/impressum',
  },
  {
    name: 'terms_of_use',
    path: 'https://www.ckw.ch/nutzungsbedingungen',
  },
  {
    name: 'legal_requirements',
    path: 'https://www.ckw.ch/gesetzliche-vorgaben',
  },
]

const socialNetworks = [
  {
    icon: <FacebookIcon />,
    path: 'https://www.facebook.com/ckw.energiezukunft/',
  },
  {
    icon: <InstagramIcon />,
    path: 'https://www.instagram.com/ckwag/',
  },
  {
    icon: <YoutubeIcon />,
    path: 'https://www.youtube.com/channel/UCIBl4Z7HF2ZZm5nBhPU9rxg',
  },
  {
    icon: <LinkedinIcon />,
    path: 'https://ch.linkedin.com/company/ckw',
  },
]

const FooterNavigation = () => {
  const { t } = useTranslation()
  return (
    <Box component="footer" mt="auto">
      <Container>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          mb={5}
          mt={{ xs: 6, sm: 11 }}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexWrap="wrap"
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} columnGap={3} flexWrap="wrap" mt={3}>
            {legalLinks.map(({ name, path }) => (
              <Link
                key={path}
                target="_blank"
                href={path}
                underline="none"
                color="textSecondary"
                fontFamily="GothamBook"
                sx={{
                  '&:hover': {
                    color: '#365F10',
                  },
                }}
              >
                {t(name)}
              </Link>
            ))}
          </Stack>
          <Stack direction="row" columnGap={2} mt={3}>
            {socialNetworks.map(({ icon, path }) => (
              <IconButton key={path} target="_blank" href={path} size="small">
                {icon}
              </IconButton>
            ))}
          </Stack>
        </Stack>
      </Container>
      <Divider sx={{ background: '#DBE873', height: 8 }} />
    </Box>
  )
}

export default FooterNavigation
