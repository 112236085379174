import House from '@assets/images/house.png'
import HouseProsumer from '@assets/images/houseProsumer.png'
import theme from '@assets/themes/theme'
import ChartBar from '@components/ChartBar/ChartBar'
import { DtpDropdown } from '@components/DtpDropdown/DtpDropdown'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import { getAggregationsStartEndDate, calculateAvailableYear } from '@helpers/dateFunctions'
import useLocationsData from '@hooks/useLocations/useLocationsData'
import useMeasurements from '@hooks/useMeasurements/useMeasurements'
import { IMeasurement } from '@interfaces/measurements'
import { ArrowForward } from '@mui/icons-material'
import { Stack, Box, Typography, useMediaQuery, Button, Skeleton } from '@mui/material'
import { HeroContainer } from '@src/ui/wrappers/HeroContainer/HeroContainer'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function DtpHeroCard() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const {
    activeLocations,
    expiredLocations,
    selectedItem,
    setSelectedItem,
    showExpiredContracts,
    isLoading: isLoadingLocations,
    handleChangeExpireContracts,
    allLocations,
  } = useLocationsData()

  const [tooltipPosition, setTooltipPosition] = useState({ x: 500, y: -80 })

  const yearToShow = calculateAvailableYear(selectedItem?.addOn?.supplyEndDate)
  const { startDate, endDate } = getAggregationsStartEndDate(yearToShow, 'year')

  const { data, isFetching } = useMeasurements({
    meterId: selectedItem?.addOn?.meterId || undefined,
    gpnr: selectedItem?.addOn?.gpnr || '',
    aggregation: 'MONTH',
    startDate,
    endDate,
    locationId: selectedItem?.addOn?.locationId || undefined,
    contractId: selectedItem?.addOn?.contractId || undefined,
  })

  const currentMonthWithoutFormat = dayjs().date() === 1 ? dayjs().subtract(1, 'month') : dayjs()

  const currentMonth = currentMonthWithoutFormat.format('MMMM')
  const currentMonthRedirect = currentMonthWithoutFormat.format('YYYY-MM')

  const monthConsumption = useMemo(() => {
    return data?.body.measurements.find(
      (month: IMeasurement) => dayjs(month.timestampStartOfPeriod).format('MMMM') === currentMonth
    )
  }, [data, currentMonth])

  const monthProsumerBallance = `${
    Number(monthConsumption?.feedIn ?? 0) > Number(monthConsumption?.consumption ?? 0) ? '+' : '-'
  } ${Math.abs(Number(monthConsumption?.feedIn ?? 0) - Number(monthConsumption?.consumption ?? 0)).toFixed(2)} kWh`

  const isFeedInLocationOrContract =
    selectedItem.addOn?.hasFeedInContractsInLocation || selectedItem.addOn?.isFeedInContract

  return (
    <HeroContainer>
      <Stack
        flexDirection={isTablet ? 'column' : 'row'}
        gap={isMobile ? 0 : 4}
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isTablet ? '100%' : '50%'}>
          <Show when={isTablet}>
            <DtpDropdown
              activeLocations={activeLocations}
              expiredLocations={expiredLocations}
              value={selectedItem.value}
              onLocationsSelect={setSelectedItem}
              noDataLabel={t('power_consumption_page.energy_no_contracts')}
              placeholder={t('power_consumption_page.energy_select_contract')}
              showExpiredContracts={showExpiredContracts}
              handleChangeExpireContracts={handleChangeExpireContracts}
              isLoading={isLoadingLocations}
            />
          </Show>
          <Stack sx={{ width: '100%', gap: 4, flexDirection: { xs: 'column-reverse', md: 'column' } }}>
            <Typography variant="heading3" sx={{ margin: 0, whiteSpace: 'nowrap' }}>
              {t(isFeedInLocationOrContract ? 'home_page.balance_in_month' : 'home_page.consumption_in_month', {
                month: currentMonth,
              })}
            </Typography>
            <Box
              component="img"
              height={277}
              src={isFeedInLocationOrContract ? HouseProsumer : House}
              style={{ objectFit: 'contain' }}
              alt="ckw"
            />
          </Stack>
          <Show when={isFetching}>
            <Skeleton variant="rounded" width={210} height={35} />
          </Show>
          <Show when={!isFetching}>
            <Typography variant="lead" sx={{ margin: 0, height: 35 }}>
              {monthConsumption?.sumOfRates && !isFeedInLocationOrContract
                ? `${Number(monthConsumption?.sumOfRates).toFixed(2)} kWh`
                : monthConsumption?.feedIn && isFeedInLocationOrContract
                  ? monthProsumerBallance
                  : t('power_consumption_page.error_message_no_data_available')}
            </Typography>
          </Show>
        </Box>

        <Box width="100%">
          <Show when={!isTablet}>
            <Box ml={4}>
              <DtpDropdown
                activeLocations={activeLocations}
                expiredLocations={expiredLocations}
                value={selectedItem.value}
                onLocationsSelect={setSelectedItem}
                noDataLabel={t('power_consumption_page.energy_no_contracts')}
                placeholder={t('power_consumption_page.energy_select_contract')}
                showExpiredContracts={showExpiredContracts}
                handleChangeExpireContracts={handleChangeExpireContracts}
                isLoading={isLoadingLocations}
              />
            </Box>
          </Show>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Show when={!isMobile}>
              <Typography ml={4} variant="bodyRegularEmphasized">
                {dayjs(yearToShow).isValid() ? yearToShow : ''}
              </Typography>
            </Show>

            <Button
              disabled={allLocations.length === 0}
              onClick={() =>
                navigate(t('navigation_url.energy_consumption'), {
                  state: { contractId: selectedItem?.value, tab: 1, value: currentMonthRedirect },
                })
              }
              variant="text"
              sx={{ marginLeft: -2 }}
              endIcon={!isMobile && <ArrowForward />}
              startIcon={isMobile && <ArrowForward />}
            >
              {t('view_details')}
            </Button>
          </Stack>
          <Show when={!isMobile}>
            <ChartBar
              data={data?.body.measurements}
              averageConsumption={data?.body.averageConsumption}
              isLoading={isFetching}
              tooltipPosition={tooltipPosition}
              noDataDateValue={yearToShow}
              setTooltipPosition={setTooltipPosition}
              handleOnDateChange={(value: string) =>
                navigate(t('navigation_url.energy_consumption'), {
                  state: {
                    value: value,
                    tab: 1,
                    contractId: selectedItem?.value,
                  },
                })
              }
              unit={'kWh'}
              xAxisDate={chartAxisEnum.MONTH}
            />
          </Show>
        </Box>
      </Stack>
    </HeroContainer>
  )
}
