import theme from '@assets/themes/theme'
import { Box, DialogTitle, Stack, Typography } from '@mui/material'
import { DateView, PickersCalendarHeader, PickersCalendarHeaderProps } from '@mui/x-date-pickers'
import { PickersArrowSwitcher } from '@mui/x-date-pickers/internals'
import dayjs, { Dayjs } from 'dayjs'

interface CustomHeaderProps {
  caledarHeaderProps: PickersCalendarHeaderProps<Dayjs>
  date: Dayjs
  viewType: DateView
  minDate?: string | number
  maxDate?: string | number
  handleChangeDate: (type: 'add' | 'subtract') => void
  validateChangeDate: (
    date: Dayjs,
    limitDate: Dayjs,
    viewType: DateView
  ) => { isAfterLastAvailableDay: boolean; isBeforeLastAvailableDay: boolean }
}

const CustomHeader = ({
  caledarHeaderProps,
  date,
  viewType,
  minDate,
  maxDate,
  handleChangeDate,
  validateChangeDate,
}: CustomHeaderProps) => {
  const { currentMonth } = caledarHeaderProps
  const customLabel =
    viewType === 'year'
      ? dayjs(minDate).format('YYYY') + ' - ' + dayjs(maxDate).format('YYYY')
      : dayjs(currentMonth).format('YYYY')
  const shouldDisableNext = validateChangeDate(date, dayjs(maxDate), viewType).isAfterLastAvailableDay
  const shouldDisablePrev = validateChangeDate(date, dayjs(minDate), viewType).isBeforeLastAvailableDay

  return (
    <>
      {viewType === 'day' ? (
        <PickersCalendarHeader
          {...caledarHeaderProps}
          slotProps={{
            switchViewButton: {
              sx: { display: 'none' },
            },
          }}
          sx={{
            padding: 0,
            [theme.breakpoints.down('sm')]: {
              pl: 4,
              pr: 2,
            },
          }}
        />
      ) : (
        <DialogTitle
          sx={{
            padding: 0,
            px: 2,
            [theme.breakpoints.down('sm')]: {
              pl: 3,
              pr: 2,
            },
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography
              id="customDialogLabel"
              sx={{
                my: 0.3,
                [theme.breakpoints.down('sm')]: {
                  my: 1,
                },
              }}
            >
              {customLabel}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
              {viewType !== 'year' && (
                <PickersArrowSwitcher
                  previousLabel=""
                  nextLabel=""
                  isPreviousDisabled={shouldDisablePrev}
                  isNextDisabled={shouldDisableNext}
                  onGoToNext={() => handleChangeDate('add')}
                  onGoToPrevious={() => handleChangeDate('subtract')}
                />
              )}
            </Box>
          </Stack>
        </DialogTitle>
      )}
    </>
  )
}

export default CustomHeader
