import { Box, Typography, Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IStepperProps, FormSteps } from './DtpAuthCard'

const RequestNewCode = ({ setStep }: IStepperProps) => {
  const { t } = useTranslation()
  return (
    <Box>
      <Typography>Request new code</Typography>
      <Box mt={3}>
        <Stack direction="row" alignItems="center" mt={4} gap={1}>
          <Button variant="contained" size="large" type="submit">
            {t('authorization.complete')}
          </Button>
          <Button variant="outlined" size="large" onClick={() => setStep(FormSteps.ACCESS_CODE_STEP)}>
            {t('back')}
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}

export default RequestNewCode
