import * as Sentry from '@sentry/react'

type ApiFunction<Args extends unknown[], T> = (...args: Args) => Promise<T>

export const withErrorLogging = <Args extends unknown[], T>(fn: ApiFunction<Args, T>): ApiFunction<Args, T> => {
  return async (...args: Args): Promise<T> => {
    try {
      return await fn(...args)
    } catch (error) {
      Sentry.captureException(error)
      Sentry.setContext('apiRequest', {
        method: fn.name,
        args,
      })
      throw error
    }
  }
}
