import { FormControlLabel, styled, Radio } from '@mui/material'

interface IDtpSelectItemProps {
  value: string
  label: string | React.ReactNode
  checked: boolean
}

const CustomItem = styled(FormControlLabel)(({ theme, checked }) => ({
  alignItems: 'flex-start',
  border: checked ? `1px solid ${theme.palette.background.primaryActive}` : `1px solid ${theme.palette.grey['50']}`,
  backgroundColor: checked ? theme.palette.primary.light : theme.palette.grey['50'],
  maxWidth: 576,
  width: '100%',
  borderRadius: 8,
  margin: theme.spacing(0, 0, 2, 0),
  padding: theme.spacing(1, 3),
  paddingLeft: theme.spacing(2),
}))

export const DtpCardSelectItem = ({ value, label, checked }: IDtpSelectItemProps) => {
  return <CustomItem value={value} control={<Radio />} label={label} checked={checked} />
}
