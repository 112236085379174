import { useMediaQuery, useTheme } from '@mui/material'
import { ToastContainer } from 'react-toastify'

const ToastifyContainer = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <ToastContainer
      position={isMobile ? 'bottom-center' : 'top-right'}
      icon={false}
      closeButton={false}
      autoClose={3000}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      style={{ padding: 0, top: '88px' }}
      toastStyle={{ padding: 0 }}
      bodyStyle={{ padding: 0 }}
    />
  )
}

export default ToastifyContainer
