import { statusType } from '@components/DtpInvoiceCard/DtpInvoiceCardUtills'
import { invoiceStatus } from '@enums/invoiceStatus'
import { Typography, Stack } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'

const InvoiceStatus = ({ title, invoiceStatus }: { title: string; invoiceStatus: invoiceStatus }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="bodySmall" fontFamily="GothamBook" sx={{ my: 0.5 }}>
        {title}
      </Typography>
      {/* check when there is no invoiceStatus */}
      <Show when={!!invoiceStatus}>
        <Stack flexDirection="row" alignItems="center">
          {statusType[invoiceStatus].icon}
          <Typography
            variant="bodySmall"
            color={statusType[invoiceStatus].textColor}
            fontFamily={'GothamBook'}
            sx={{ my: 0.5 }}
          >
            {statusType[invoiceStatus].text}
          </Typography>
        </Stack>
      </Show>
    </Stack>
  )
}

export default InvoiceStatus
