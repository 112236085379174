import { updateInvoiceSettings } from '@api/mutations'
import { getInvoiceSettings } from '@api/queries'
import useAccounts from '@hooks/useAccounts/useAccounts'
import { IInvoicesSettingsApiResponse } from '@interfaces/invoices'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

const useInvoiceSettings = ({ contractAccountId }: { contractAccountId?: string }) => {
  const { activeAccount } = useAccounts()

  const gpnr = activeAccount?.gpnr

  const {
    data,
    isFetching: isLoadingInvoiceSettings,
    isError: isInvoiceSettingsError,
    refetch: refetchInvoices,
  } = useQuery<IInvoicesSettingsApiResponse>({
    queryKey: ['invoices', gpnr, contractAccountId],
    queryFn: () =>
      getInvoiceSettings({
        gpnr,
        contractAccountId,
      }),
    cacheTime: 0,
    enabled: !!gpnr && !!contractAccountId,
  })

  const invoiceSettings = useMemo(() => data?.body, [data])

  const { mutateAsync: updateDeliveryMethod, isLoading: isUpdatingDeliveryMethod } = useMutation({
    mutationKey: ['updateInvoiceSettings'],
    mutationFn: ({
      paymentDeliveryType,
      contractAccountId,
    }: {
      paymentDeliveryType: string
      contractAccountId: string
    }) => updateInvoiceSettings({ gpnr, contractAccountId, paymentDeliveryType }),
  })

  return {
    invoiceSettings,
    isLoadingInvoiceSettings,
    isInvoiceSettingsError,
    refetchInvoices,
    updateDeliveryMethod,
    isUpdatingDeliveryMethod,
  }
}

export default useInvoiceSettings
