import { InputBase, InputBaseProps, TextFieldProps } from '@mui/material'
import React from 'react'

import DtpFormWrapper, { FormProps } from '../DtpFormWrapper/DtpFormWrapper'

type ComposedTextFieldProps = TextFieldProps & InputBaseProps & FormProps

const DtpTextField = React.forwardRef<HTMLInputElement, ComposedTextFieldProps>((props, ref) => {
  return (
    <DtpFormWrapper {...props}>
      <InputBase ref={ref} {...props} />
    </DtpFormWrapper>
  )
})

DtpTextField.displayName = 'DtpTextField'

export default DtpTextField
