import { CheckIcon } from '@assets/icons'
import CardDot from '@components/DtpBillsCard/CardDot'
import { IDtpInvoiceCardProps } from '@components/DtpInvoiceCard/DtpInvoiceCard'
import { statusType } from '@components/DtpInvoiceCard/DtpInvoiceCardUtills'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { Box, Typography, Stack, styled } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const GruopCardWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  border: '1px solid #D5D8D5',
  borderBottom: 'none',
  borderTop: 'none',
  padding: theme.spacing(1, 2),
  cursor: 'pointer',
  backgroundColor: '#FAFAFA',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '16px',
    width: 'calc(100% - 32px)',
    height: '1px',
    backgroundColor: '#D5D8D5',
  },
  '&:first-of-type': {
    borderTop: '1px solid #D5D8D5',
    borderTopLeftRadius: '30px',
    borderTopRightRadius: '30px',
    borderBottom: 'none',
  },
  '&:last-of-type': {
    borderTop: 'none',
    borderBottomLeftRadius: '30px',
    borderBottomRightRadius: '30px',
    borderBottom: '1px solid #D5D8D5',
    '&::after': {
      content: 'none',
    },
  },
}))

const GroupCard = ({
  currency,
  amountTotal,
  contractId,
  dueDate,
  eBill,
  invoiceStatus,
  invoiceId,
}: IDtpInvoiceCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <GruopCardWrapper role="open-invoice-card" onClick={() => navigate(`${t('navigation_url.invoices')}/${invoiceId}`)}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <CardDot sx={{ width: 12, height: 12, marginRight: 2 }} color={statusType[invoiceStatus].bgColor} />
          <Box>
            <Typography variant="bodySmallEmphasized" sx={{ my: 0 }}>
              {contractId}
            </Typography>
            <Typography variant="caption" sx={{ my: 0 }} color={statusType[invoiceStatus].textColor}>
              {`${t('invoices.invoice_due_date')} ${dayjs(dueDate).format('DD.MM.YY')}`}
            </Typography>
            <Show when={eBill}>
              <Stack flexDirection="row" alignItems={'center'}>
                <CheckIcon color="success" fontSize="small" />
                <Typography variant="caption" fontFamily={'GothamBook'} sx={{ my: 0, ml: 0.5, mt: 0.5 }}>
                  {t('invoices.invoice_ebill')}
                </Typography>
              </Stack>
            </Show>
          </Box>
        </Box>
        <Show when={!!amountTotal}>
          <Stack flexDirection="row" alignItems="center">
            <Typography variant="bodySmallEmphasized" sx={{ my: 0, mr: 1 }}>{`${currency}  ${amountTotal}`}</Typography>
            <ArrowForward color="primary" />
          </Stack>
        </Show>
      </Stack>
    </GruopCardWrapper>
  )
}

export default GroupCard
